import { getNewMessagesCount, getAllMessages, getChats, getNewMessages } from './selectors';
import { createRfqChat, updateChats, receiveMessages } from './actions';
import initChats from './sagas';
import reducer from './reducers';
import * as constants from './constants';
import Chat from './components/Chat';
import ActiveMessaging from './components/ActiveMessaging';

const selectors = {
  getNewMessagesCount,
  getAllMessages,
  getChats,
  getNewMessages,
};

const reducers = {
  reducer,
};

const actions = {
  createRfqChat,
  updateChats,
  receiveMessages,
};

const sagas = {
  initChats,
};

const components = {
  Chat,
  ActiveMessaging,
};

export default { selectors, sagas, reducers, constants, components, actions };
