import { takeEvery, put, select } from 'redux-saga/effects';

import {
  NAME,
  INIT_WORKSPACE,
  UPDATE_WORKSPACES,
  UPDATE_WORKSPACE_LAYOUTS,
  ADD_LAYOUT_WIDGET,
  REMOVE_LAYOUT_WIDGET,
  ACTIVATE_WORKSPACE,
  INIT_WORKSPACE_SUCCESS,
  ACTIVATE_WORKSPACE_SUCCESS,
  UPDATE_WORKSPACE_LAYOUTS_SUCCESS,
  ADD_COMPONENT_SUCCESS,
  RESET_WORKSPACE,
  CLEAR_WORKSPACE,
  RESET_WORKSPACE_SUCCESS,
  CLEAR_WORKSPACE_SUCCESS,
  ADD_COMPONENT,
  UPDATE_WORKSPACE,
  UPDATE_WORKSPACE_SUCCESS,
  REMOVE_LAYOUT_WIDGET_SUCCESS,
  REMOVE_WIDGET_SUCCESS,
  REMOVE_WIDGET,
  UPDATE_WIDGET,
  UPDATE_WIDGET_SUCCESS,
  SAVE_WORKSPACES,
  UPDATE_MANAGE_WORKSPACES,
  UPDATE_MANAGE_WORKSPACES_SUCCESS,
  UPDATE_MANAGE_WORKSPACES_ERROR,
  UPDATE_WORKSPACES_SUCCESS,
} from '../constants';

import { defaultWorkspace, blankWorkspace, getDefaultWorkspaces } from '../config';

import { getFromLS, saveToLS, removeFromLS } from '../../../commons/localStorage';

import {
  initWorkspace as actionInitWorkspace,
  activateWorkspace as actionActivateWorkspace,
  resetWorkspace as actionResetWorkspace,
  clearWorkspace as actionClearWorkspace,
  addComponent as actionAddComponent,
  removeLayoutWidget as actionRemoveLayoutWidget,
  removeWidget as actionRemoveWidget,
  updateWidget as actionUpdateWidget,
  updateWorkspace as actionUpdateWorkspace,
  updateWorkspaces as actionUpdateWorkspaces,
  updateWorkspaceLayouts as actionUpdateWorkspaceLayouts,
  addLayoutWidget as actionAddLayoutWidget,
  updateManageWorkspaces as actionUpdateManageWorkspaces,
} from '../actions';

import { getAll, getDefault, getActiveWorkspace, getManageWorkspaces } from '../selectors';

import { widgets } from '../config/widgets';

import auth from '../../auth';

function* initWorkspace() {
  const defaultItem = defaultWorkspace();
  const defaultWorkspaces = getDefaultWorkspaces();
  defaultItem.id = new Date().getTime().toString();

  let items = getFromLS(NAME) || defaultWorkspaces;

  const hasActive = items.some((item) => item.active === true);
  if (!hasActive) {
    items[0].active = true;
  }

  yield put(
    actionInitWorkspace(INIT_WORKSPACE_SUCCESS, { items, defaultItem }, { receivedAt: new Date() }),
  );
  // yield put(actionUpdateManageWorkspaces(UPDATE_MANAGE_WORKSPACES, items));
}

function* activateWorkspace(action) {
  const items = yield select(getAll);

  items.forEach((item) => {
    item.active = item.id === action.payload;
  });

  // yield put(actionUpdateManageWorkspaces(items));

  saveToLS('workspaces', items);

  yield put(
    actionActivateWorkspace(ACTIVATE_WORKSPACE_SUCCESS, { items }, { receivedAt: new Date() }),
  );
}

function* resetWorkspace() {
  const defaultWorkspace = yield select(getDefault);
  const workspaces = yield select(getAll);
  workspaces.some((workspace, i) => {
    if (workspace.active) {
      const { layouts, widgets } = defaultWorkspace;

      workspace.layouts = [...layouts];
      workspace.widgets = [...widgets];
      workspaces[i] = { ...workspace };
      return true;
    }
    return false;
  });

  saveToLS('workspaces', workspaces);

  yield put(
    actionResetWorkspace(
      RESET_WORKSPACE_SUCCESS,
      { items: [...workspaces] },
      { receivedAt: new Date() },
    ),
  );
}

function* clearWorkspace() {
  const workspaces = yield select(getAll);

  workspaces.some((workspace, i) => {
    if (workspace.active) {
      workspace.layouts.length = 0;
      workspace.widgets.length = 0;
      workspaces[i] = { ...workspace };
      return true;
    }
    return false;
  });

  saveToLS('workspaces', workspaces);

  yield put(
    actionClearWorkspace(
      CLEAR_WORKSPACE_SUCCESS,
      { items: [...workspaces] },
      { receivedAt: new Date() },
    ),
  );
}

function* addComponent(action) {
  const { type } = action.payload;
  const widget = widgets.find((item) => item.type === type);

  const id = new Date().getTime().toString();
  const newWidget = { ...widget, id, i: id };

  const layout = {
    w: newWidget.w,
    h: newWidget.h,
    x: 0,
    y: Infinity,
    i: newWidget.id,
  };

  const workspaces = yield select(getAll);

  workspaces.some((workspace, i) => {
    if (workspace.active) {
      workspace.layouts.push(layout);
      workspace.widgets.push(newWidget);
      workspaces[i] = { ...workspace };
      return true;
    }

    return false;
  });

  saveToLS('workspaces', workspaces);

  yield put(
    actionAddComponent(
      ADD_COMPONENT_SUCCESS,
      { items: [...workspaces] },
      { receivedAt: new Date() },
    ),
  );
}

function* updateWorkspace(action) {
  const { layouts: newLayouts, widgets: newWidgets } = action.payload;

  const activeWorkspace = yield select(getActiveWorkspace);

  if (newLayouts) {
    activeWorkspace.layouts = [...newLayouts];
  }

  if (newWidgets) {
    activeWorkspace.widgets = [...newWidgets];
  }

  // activeWorkspace.layouts = { ...action.payload.layouts };

  const workspaces = yield select(getAll);
  // const workspaces = action.payload;

  saveToLS('workspaces', workspaces);

  yield put(
    actionUpdateWorkspace(
      UPDATE_WORKSPACE_SUCCESS,
      { items: workspaces },
      { receivedAt: new Date() },
    ),
  );
}

function* removeLayoutWidget(action) {
  const { item } = action.payload;
  const id = String(item.i);

  const workspaces = yield select(getAll);

  workspaces.some((workspace, i) => {
    if (workspace.active) {
      workspace.layouts = workspace.layouts.filter((layout) => {
        return String(layout.i) !== id;
      });

      workspace.widgets = workspace.widgets.filter((widget) => {
        return String(widget.i) !== id;
      });

      workspaces[i] = { ...workspace };

      return true;
    }
    return false;
  });

  saveToLS('workspaces', workspaces);

  yield put(
    actionRemoveLayoutWidget(
      REMOVE_LAYOUT_WIDGET_SUCCESS,
      { items: workspaces },
      { receivedAt: new Date() },
    ),
  );
}

function* removeWidget(action) {
  const { item } = action.payload;
  const id = String(item.i);

  const workspaces = yield select(getAll);

  workspaces.some((workspace, i) => {
    if (workspace.active) {
      workspace.widgets = workspace.widgets.filter((widget) => {
        return String(widget.i) !== id;
      });

      workspaces[i] = { ...workspace };

      return true;
    }
    return false;
  });

  saveToLS('workspaces', workspaces);

  yield put(
    actionRemoveWidget(REMOVE_WIDGET_SUCCESS, { items: workspaces }, { receivedAt: new Date() }),
  );
}

function* updateWidget(action) {
  const { item } = action.payload;

  const id = String(item.i);

  const workspaces = yield select(getAll);

  workspaces.some((workspace, i) => {
    if (workspace.active) {
      workspace.widgets = workspace.widgets.filter((widget) => {
        return String(widget.i) !== id;
      });

      workspace.widgets.push(item);

      return true;
    }
    return false;
  });

  saveToLS('workspaces', workspaces);

  yield put(
    actionUpdateWidget(UPDATE_WIDGET_SUCCESS, { items: workspaces }, { receivedAt: new Date() }),
  );
}

function* updateWorkspaces(action) {
  const workspaces = action.payload;

  saveToLS('workspaces', workspaces);

  yield put(
    actionUpdateWorkspaces(
      UPDATE_WORKSPACES_SUCCESS,
      { items: workspaces },
      { receivedAt: new Date() },
    ),
  );
  /* const workspaces = action.payload;

  yield put(actionUpdateManageWorkspaces(workspaces));
  yield saveToLS('workspaces', workspaces); */
}

function* saveWorkspaces(action) {
  const workspaces = action.payload;

  yield saveToLS('workspaces', workspaces);
}

function* updateWorkspaceLayouts(action) {
  const workspaces = yield select(getAll);

  workspaces.some((workspace) => {
    if (workspace.active) {
      const { layouts, widgets } = action.payload;

      workspace.layouts = layouts;
      workspace.widgets = widgets;

      return true;
    }
    return false;
  });

  saveToLS('workspaces', workspaces);

  yield put(
    actionUpdateWorkspaceLayouts(
      UPDATE_WORKSPACE_LAYOUTS_SUCCESS,
      { items: [...workspaces] },
      { receivedAt: new Date() },
    ),
  );
}

function* addLayoutWidget() {
  const items = getFromLS(NAME) || [yield select(getDefault)];

  yield put(actionAddLayoutWidget({ items }, { receivedAt: new Date() }));
}

// TODO: REFACTOR METHOD. EXTRACT ELSE IF IN SINGLE METHOD AND DYNAMIC INVOKE.
function* updateManageWorkspaces(action) {
  try {
    const {
      payload: { action: actioManageWorkspaces, id, value },
    } = action;

    const payload = {};
    const meta = { receivedAt: new Date() };

    if (actioManageWorkspaces === 'showManage') {
      payload.open = true;

      const items = yield select(getAll);
      payload.items = JSON.parse(JSON.stringify(items));
    } else if (actioManageWorkspaces === 'closeManage') {
      payload.open = false;
      payload.items = [];
    } else if (actioManageWorkspaces === 'changeName') {
      const { items } = yield select(getManageWorkspaces);
      const workspaces = [...items];

      const workspace = workspaces.find((w) => w.id === id);
      workspace.name = value;

      payload.items = workspaces;
    } else if (actioManageWorkspaces === 'deleteWorkspace') {
      const { items } = yield select(getManageWorkspaces);
      const workspaces = [...items];

      let active = false;
      if (workspaces.length > 1) {
        const newWorkspaces = [];

        workspaces.forEach((w) => {
          if (w.id !== id) {
            newWorkspaces.push(w);
          } else {
            active = w.active;
          }
        });

        if (active) {
          newWorkspaces[0].active = true;
        }

        payload.items = newWorkspaces;
      }
    } else if (actioManageWorkspaces === 'addWorkspace') {
      const { items } = yield select(getManageWorkspaces);
      const workspaces = [...items];
      workspaces.push(blankWorkspace(workspaces.length + 1));
      payload.items = workspaces;
    } else if (actioManageWorkspaces === 'copyWorkspace') {
      const { items } = yield select(getManageWorkspaces);
      const workspaces = [...items];

      const num = workspaces.length;
      const duplicateWorkspace = workspaces.find((w) => w.id === id);
      const newWorkspace = blankWorkspace(num);
      newWorkspace.active = false;

      let newId = new Date().getTime().toString();

      duplicateWorkspace.layouts.forEach((layout) => {
        newId = `${parseInt(newId, 10) + 1}`;
        const newLayout = JSON.parse(JSON.stringify(layout));
        const widget = duplicateWorkspace.widgets.find((w) => w.i === layout.i);
        const newWidget = JSON.parse(JSON.stringify(widget));
        newLayout.i = newId;
        newWidget.i = newId;
        newWorkspace.layouts.push(newLayout);
        newWorkspace.widgets.push(newWidget);
      });

      newWorkspace.name = `${duplicateWorkspace.name} (COPY)`;
      newWorkspace.active = false;
      workspaces.push(newWorkspace);

      payload.items = workspaces;
    } else if (actioManageWorkspaces === 'toogleWorkspace') {
      const { items } = yield select(getManageWorkspaces);
      const workspaces = [...items];

      workspaces.forEach((w) => {
        if (w.id === id) {
          if (w.active === false) {
            w.active = true;
          }
        } else {
          w.active = false;
        }
      });

      payload.items = workspaces;
    } else if (actioManageWorkspaces === 'saveWorkspaces') {
      const { items } = yield select(getManageWorkspaces);
      const workspaces = [...items];

      payload.open = false;
      payload.items = [];

      yield put(actionUpdateWorkspaces(UPDATE_WORKSPACES, workspaces));
    }

    yield put(actionUpdateManageWorkspaces(UPDATE_MANAGE_WORKSPACES_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    yield put(
      actionUpdateManageWorkspaces(
        UPDATE_MANAGE_WORKSPACES_ERROR,
        { error },
        { receivedAt: new Date() },
      ),
    );
  }
}

export function* watchInitWorkspace() {
  yield takeEvery(INIT_WORKSPACE, initWorkspace);
}

export function* watchActivateWorkspace() {
  yield takeEvery(ACTIVATE_WORKSPACE, activateWorkspace);
}

export function* watchResetWorkspace() {
  yield takeEvery(RESET_WORKSPACE, resetWorkspace);
}

export function* watchClearWorkspace() {
  yield takeEvery(CLEAR_WORKSPACE, clearWorkspace);
}

export function* watchAddComponent() {
  yield takeEvery(ADD_COMPONENT, addComponent);
}

export function* watchUpdateWorkspace() {
  yield takeEvery(UPDATE_WORKSPACE, updateWorkspace);
}

export function* watchRemoveLayoutWidget() {
  yield takeEvery(REMOVE_LAYOUT_WIDGET, removeLayoutWidget);
}

export function* watchRemoveWidget() {
  yield takeEvery(REMOVE_WIDGET, removeWidget);
}

export function* watchUpdateWidget() {
  yield takeEvery(UPDATE_WIDGET, updateWidget);
}

export function* watchUpdateWorkspaces() {
  yield takeEvery(UPDATE_WORKSPACES, updateWorkspaces);
}

export function* watchSaveWorkspaces() {
  yield takeEvery(SAVE_WORKSPACES, saveWorkspaces);
}

export function* watchUpdateWorkspaceLayouts() {
  yield takeEvery(UPDATE_WORKSPACE_LAYOUTS, updateWorkspaceLayouts);
}

export function* watchAddLayoutWidget() {
  yield takeEvery(ADD_LAYOUT_WIDGET, addLayoutWidget);
}

export function* watchUpdateManageWorkspaces() {
  yield takeEvery(UPDATE_MANAGE_WORKSPACES, updateManageWorkspaces);
}

function signOutSuccess() {
  removeFromLS(NAME);
}

export function* watchSignOutSuccess() {
  yield takeEvery(auth.constants.SIGN_OUT_SUCCESS, signOutSuccess);
}
