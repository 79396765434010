import lightBlue from '@material-ui/core/colors/lightBlue';
import teal from '@material-ui/core/colors/teal';
import amber from '@material-ui/core/colors/amber';
import red from '@material-ui/core/colors/red';

export const dark = {
  overrides: {
    MuiPaper: {
      elevation4: {
        boxShadow: 'none',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: lightBlue['400'],
      },
    },
    MuiPickersDay: {
      isSelected: {
        backgroundColor: lightBlue['400'],
      },
      current: {
        color: lightBlue['900'],
      },
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: '2px',
        marginBottom: '2px',
      },
    },
    MuiInput: {
      input: {
        underline: {
          '&:after': {
            borderBottom: `0px !important`,
          },
        },
      },
      formControl: {
        'label + &': {
          //marginTop: '20px',
        },
      },
    },
    MuiInputLabel: {
      root: {
        padding: 2,
        '&$focused': {
          color: 'rgba(225,225,255,.8)',
        },
      },
      shrink: {
        transform: 'translate(6px, 6px) scale(0.85) !important',
      },
      marginDense: {
        transform: 'translate(6px, 6px) scale(0.85) !important',
      },
    },

    MuiInputAdornment: {
      root: {
        '& [class*="MuiTypography-root"]': {
          whiteSpace: 'normal',
        },
      },
    },
    MuiMenuItem: {
      root: {
        minHeight: '48px',
        '@media (min-width: 600px)': {
          minHeight: '48px',
        },
      },
    },

    MuiChip: {
      root: {
        minHeight: '18px',
        height: 'none',

        backgroundColor: 'rgba(255,255,255,.2)',
      },
    },
    MuiAvatar: {
      img: {
        backgroundColor: 'transparent',
      },
      root: {
        height: '32px',
        width: '32px',
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: lightBlue[700],
        rippleBackgroundColor: lightBlue[700],
        opacity: 1.0,
        filter: 'alpha(opacity=100)',
        fontSize: '1em',
      },
      popper: {
        opacity: 1.0,
        filter: 'alpha(opacity=100)',
      },
    },
    MuiButton: {
      textPrimary: {
        color: 'rgb(255,255,255)',
      },
      root: {
        lineHeight: 1,
      },
    },

    MuiToolbar: {
      gutters: {
        paddingLeft: '16px',
        paddingRight: '16px',
        '@media (min-width: 600px)': {
          paddingLeft: '16px',
          paddingRight: '16px',
        },
      },
    },
    MuiInputBase: {
      inputMarginDense: {
        paddingTop: '5px',
        paddingBottom: '2px',
      },
    },
    MuiTypography: {
      root: {
        padding: '0px 4px',
        fontSize: '1em',
      },
    },
    MuiSwitch: {
      switchBase: {
        color: '#ccc',
      },
      colorSecondary: {
        '&$checked': {
          color: amber[500],
        },
      },
      track: {
        opacity: 0.2,
        backgroundColor: '#fff',
        '$checked$checked + &': {
          opacity: 0.7,
          backgroundColor: amber[500],
        },
      },
    },
    MuiTabs: {
      fixed: { backgroundColor: '#092e37' },
    },
    PrivateTabIndicator: {
      colorSecondary: {
        backgroundColor: teal['400'],
      },
    },
  },

  palette: {
    type: 'dark',

    background: {
      primary: '#0b353f',
      //dark: '#07272e',
      dark: '#092e37',
      light: '#0d3e4a', //'#1d4f61',
      lighter: '#104b59',
      paper: '#20586c',
      default: '#0b353f',
      widget: '#20586c',
      appBar: '#20586c',
      banner: '#204a5a',
      rfqTabBuy: 'rgb(24,59,85)',
      rfqTabSell: 'rgb(24,56,64)',
      userTab: 'rgb(255,0,0)',
      userTabold: 'rgb(30,40,54)',
      tile: 'rgb(0,0,0,.2)',
      row: '#1e5164',
      darkRow: '#07272e',
      innerAlt: '#0b353f',
      inner: '#1d4f61',
      bidBackground: '#0b5161',
      offerBackground: '#084949',
      card: '#135A6A',
      filled: 'rgba(255,255,255,.09)',
      blottersMenuBar: '#092e37',
      chatMessagesArea: '#0b353f',
      activeTab: '#20586c',
      toolbarBottom: '#092e37',
    },
    common: { black: '#000', white: '#fff' },
    primary: {
      main: '#20586c', //color for headers  i.e. "RFQ VIEWER"
      dark: '#1b3e4d',
      // light: "rgba(30, 40, 54 ,1)",
      row: '#1d3d48',
      contrastText: '#fff',
    },
    secondary: {
      light: 'rgb(255, 138, 123)',
      main: 'rgb(219, 89, 79)',
      dark: 'rgb(164, 39, 38)',
      contrastText: '#fff',
    },

    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff',
    },

    text: {
      primary: '#fff',
      primaryLight: 'rgb(225,225,225)',
      opacity: 'rgb(255,255,255,.3)',
      secondary: 'rgba(225,225,255,.50)',
      //disabled: 'rgba(93, 117, 152, .87)',
      disabled: 'rgba(255,255,255,.50)',
      faded: 'rgba(255,255,255,.2)',
      hint: '#30a9a4',
      subtitle: 'rgba(240,242,250,.7)',
      faded_50: 'rgba(255,255,255,.50)',
      faded_75: 'rgba(255,255,255,.75)',
      faded_75_white: 'rgba(255,255,255,.75)',
    },
    divider: 'rgba(255,255,255,.1)',
    dividerAlt: '#38697a',
    tableBorder: 'rgba(255,255,255,.05)',
    scrollbar: '#07272e',
    chatMessages: 'rgb(240,240,240)',
    teal: teal['400'],
    teal_dark: teal['900'],
    teal_A400: teal['A400'],
    lightBlue: lightBlue['400'],
    lightBlue_A400: lightBlue['A400'],
    amber: '#c96001',
    amber_dark: '#4a422a',
    amber_A400: amber['A400'],
    amber_500: amber['500'],
    amber_700: amber['700'],
    red: red[400],
    bidBorder: '#23aec9',
    offerBorder: '#24bbac',
    widgetBorder: '#38697a',
    icon: '#fff',
    clearButtonBorder: 'rgba(255,255,255,.1)',
  },

  fontSize: {
    xxxl: '3.0rem',
    xxl: '2.0rem',
    xl: '1.5rem',
    lg: '1.25rem',
    md: '1.0rem',
    sm: '.875rem',
    xs: '.7rem',
    xxs: '.5rem',
  },
  typography: {
    useNextVariants: true,
    fontSize: 14,
    //htmlFontSize: 18,
  },
};
