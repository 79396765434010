import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Button, Icon, Badge } from '@material-ui/core';
import teal from '@material-ui/core/colors/teal';
import red from '@material-ui/core/colors/red';
import lightBlue from '@material-ui/core/colors/lightBlue';
import amber from '@material-ui/core/colors/amber';
import CustomText from './formFields/customText';

const styles = {
  button: {
    border: '1px solid',
    padding: '4px 8px',
    margin: '4px',
    minWidth: '132px',
    color: '#eee',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '.8em',
  },

  confirm: {
    //color: teal[100],
    backgroundColor: teal[400],
    borderColor: teal[400],
    '&:hover': {
      backgroundColor: teal[200],
    },
  },
  cancel: {
    //color: red[100],
    backgroundColor: red[400],
    borderColor: red[400],
    '&:hover': {
      backgroundColor: red[200],
    },
  },
  reset: {
    //color: lightBlue[100],
    backgroundColor: lightBlue[400],
    borderColor: lightBlue[400],
    '&:hover': {
      backgroundColor: lightBlue[200],
    },
  },
  save: {
    backgroundColor: '#c96001',
    borderColor: '#c96001',
    '&:hover': {
      backgroundColor: amber[200],
    },
  },

  clear: {
    backgroundColor: 'transparent',

    '&:hover': {
      backgroundColor: 'transparent',
    },
    borderColor: 'rgba(233,237,239)',
  },
  iconClear: {
    color: 'rgb(88,99,115)',
  },
  icon: {
    color: 'rgb(255,255,255)',
  },

  disabled: {
    borderColor: 'rgba(255,255,255,.1)',
  },
};

export const ConfirmButton = (props) => {
  return (
    <Button
      style={
        props.disabled
          ? { ...styles.button, ...styles.disabled, ...props.style }
          : { ...styles.button, ...styles.confirm, ...props.style }
      }
      onClick={props.onClick}
      disabled={props.disabled}
    >
      <CustomText value={props.title ? props.title : 'send'} upperCase />
      <Icon style={{ ...styles.icon, ...props.iconStyle }}>
        {props.icon ? props.icon : 'check'}
      </Icon>
    </Button>
  );
};

export const CancelButton = (props) => {
  return (
    <Button style={{ ...styles.button, ...styles.cancel, ...props.style }} onClick={props.onClick}>
      <CustomText value={props.title ? props.title : 'cancel'} upperCase />
      <Icon style={{ ...styles.icon, ...props.iconStyle }}>
        {props.icon ? props.icon : 'cancel'}
      </Icon>
    </Button>
  );
};

export const ResetButton = (props) => {
  return (
    <Button style={{ ...styles.button, ...styles.reset, ...props.style }} onClick={props.onClick}>
      <CustomText value={props.title ? props.title : 'reset'} upperCase />
      <Icon style={{ ...styles.icon, ...props.iconStyle }}>
        {props.icon ? props.icon : 'replay'}
      </Icon>
    </Button>
  );
};

export const SaveButton = (props) => {
  return (
    <Button style={{ ...styles.button, ...styles.save, ...props.style }} onClick={props.onClick}>
      <CustomText value={props.title ? props.title : 'save'} upperCase />
      <Icon style={{ ...styles.icon, ...props.iconStyle }}>{props.icon ? props.icon : 'save'}</Icon>
    </Button>
  );
};

const buttonStyles = (theme) => ({
  button: {
    border: '1px solid',
    borderColor: theme.palette.clearButtonBorder,
    padding: '4px 8px',
    margin: '4px',
    minWidth: '132px',
    color: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '.8em',
  },
  icon: {
    color: theme.palette.icon,
  },
});

export const ClearButton = withStyles(buttonStyles)((props) => {
  const { classes } = props;

  return (
    <Button className={classes.button} style={{ ...props.style }} onClick={props.onClick}>
      <CustomText value={props.title ? props.title : 'preset'} upperCase />
      <Badge badgeContent={props.badgeContent} color="secondary">
        <Icon className={classes.icon} style={{ ...props.iconStyle }}>
          {props.icon ? props.icon : 'archive'}
        </Icon>
      </Badge>
    </Button>
  );
});
