import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Progress from '../../../commons/components/progress';
import { connect } from 'react-redux';
import UserTab from './common/UserTab';
import Messages from './common/Messages';
import InputArea from './common/InputArea';
import { sendMessage as sendMessageAction, loadHistory as loadHistoryAction } from '../actions';
import { getMappedChats } from '../selectors';

const styles = (theme) => ({
  activeSubContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.header,
    maxHeight: '100%',
  },
});

const ActiveMessaging = ({
  classes,
  selectedChatId,
  sendMessage,
  loadHistory,
  chats,
  isChatVisible = true,
  style = {},
}) => {
  if (!selectedChatId) {
    return <Progress />;
  }
  const selectedChat = chats.find((item) => item.id === selectedChatId) || {};
  const [chatAllowed, setChatAllowed] = useState(true);
  const [text, setMessage] = useState('');
  useEffect(() => {
    if (selectedChat.type === 'GLOBAL') {
      setChatAllowed(false);
    } else {
      setChatAllowed(true);
    }
    setMessage('');
    // loadHistory(selectedChatId); // TODO: add when update count messages
  }, [selectedChatId]);

  const processMessageSend = () => {
    sendMessage({ text, chatId: selectedChatId });
    setMessage('');
  };
  const allowChat = () => setChatAllowed(true);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      processMessageSend();
      e.preventDefault();
    }
  };

  return (
    <div className={classes.activeSubContainer} style={style}>
      <UserTab roomInfo={selectedChat} />
      <Messages
        messages={selectedChat.messages}
        selectedChatId={selectedChatId}
        showMessages={chatAllowed}
        loadHistory={loadHistory}
        isChatVisible={isChatVisible}
      />
      <InputArea
        chatAllowed={chatAllowed}
        allowChat={allowChat}
        sendMessage={processMessageSend}
        message={text}
        setMessage={setMessage}
        keyPress={handleKeyPress}
        isChatVisible={isChatVisible}
      />
    </div>
  );
};

ActiveMessaging.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedChatId: PropTypes.string,
  sendMessage: PropTypes.func.isRequired,
  loadHistory: PropTypes.func.isRequired,
  chats: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    chats: getMappedChats(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  sendMessage: (payload) => {
    dispatch(sendMessageAction(payload));
  },
  loadHistory: (selectedChatId) => {
    dispatch(loadHistoryAction(selectedChatId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ActiveMessaging));
