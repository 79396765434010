import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Modal, Typography, Card, Tooltip, IconButton } from '@material-ui/core';
import { isAmendTradeOpen, getAllObject, getAmendTradeId, getMyOrganisation } from '../selectors';
import { AMEND_TRADE_FORM_OPEN, TRADE_AMENDMENT } from '../constants';
import { createAction } from '../../../commons/actions';
import ToolbarBottom from '../../../commons/components/toolbarBottom';
import {
  CancelButton,
  ClearButton,
  ResetButton,
  ConfirmButton,
} from '../../../commons/components/buttons';
import {
  getFieldsArray,
  getTradeAmendEnumerations,
  getCartonNetWeightEnums,
} from '../../contracts/utils/modelFunctions';
import TextFieldFilled from '../../../commons/components/formFields/textFieldFilled';
import SelectField from '../../../commons/components/formFields/SelectField';
import DateField from '../../../commons/components/formFields/dateField';
import NumberFieldCustom from '../../../commons/components/formFields/numberFieldCustom';
import DeleteIcon from '@material-ui/icons/Delete';
import marketSegments from '../../contracts/models/marketSegments';
import { createUuid } from '../../../commons/config/functions';
import { NAME } from '../../organisations/constants';
import SnackbarCustom from '../../../commons/components/snackbarCustom';
import TotalsRow from './TotalsRow';
import CustomText from '../../../commons/components/formFields/customText';
const ERROR_RECIPIENT = 'At least one organisation must be selected.';
const FIELD_WIDTH = '150px';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const getNewAssets = (orders) => {
  const species = orders[0].contract.underlying.species;
  const marketSegment = Object.keys(marketSegments).find(
    (item) => marketSegments[item].species === species,
  );
  const orderWeights = orders.map((o) => o.contract.underlying.weightClass);
  const weightClasses = Object.keys(marketSegments[marketSegment].enums.weightClass) || [];

  let nextWeightClasses = weightClasses.filter((wgt) => !orderWeights.some((w) => w === wgt));

  if (nextWeightClasses.length === 0) {
    return orders;
  } else {
    const newOrder = JSON.parse(JSON.stringify(orders[orders.length - 1]));
    const id = createUuid();

    newOrder.id = id;
    newOrder.contract.underlying.weightClass = nextWeightClasses[0];
    newOrder.price = 0;
    newOrder.volume = 0;
    orders.push(newOrder);
    orders.sort((a, b) => {
      const aIndex = weightClasses.indexOf(a.contract.underlying.weightClass);
      const bIndex = weightClasses.indexOf(b.contract.underlying.weightClass);

      return aIndex > bIndex ? 1 : bIndex > aIndex ? -1 : 0;
    });
    return orders;
  }
};
const styles = (theme) => ({
  paper: {
    position: 'absolute',
    width: '90vw',
    height: '90vh',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflowY: 'scroll',
    backgroundColor: theme.palette.background.primary,
  },

  section: {
    marginTop: theme.spacing(0.5),
    //backgroundColor: theme.palette.primary.row,
  },
  sectionFlex: {
    marginTop: theme.spacing(0.5),
    //flex: 1,
    //display: "flex",
    //flexDirection: "column",
    //overflow: "auto"
  },
  subsection: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(0.5),
  },
  totals: {
    display: 'flex',
    flexDirection: 'column',
  },

  orders: {
    flex: 1,
  },
  banner: {
    color: theme.palette.text.disabled,
    fontSize: theme.typography.fontSize,
  },
  toolbarTop: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.toolbarBottom,
  },
});
const TradeAmendment = (props) => {
  const { open, classes, tradeId, trades } = props;
  const [originalTrade, setOriginalTrade] = useState(null);
  const [amendedTrade, setAmendedTrade] = useState(null);
  const [formFields, setFormFields] = useState([]);
  const [formFieldsTop, setFormFieldsTop] = useState([]);
  const [formFieldsLeft, setFormFieldsLeft] = useState([]);
  const [formFieldsBottom, setFormFieldsBottom] = useState([]);
  const [formFieldsRight, setFormFieldsRight] = useState([]);
  const [assetRowFields, setAssetRowFields] = useState([]);
  const [enumerations, setEnumerations] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [snackbarSuccessOpen, setSnackbarSuccessOpen] = useState(false);
  const [snackbarErrorOpen, setSnackbarErrorOpen] = useState(false);
  const [cartonNetWeightEnums, setCartonNetWeightEnums] = useState([]);
  useEffect(() => {
    if (tradeId) {
      if (trades) {
        if (trades[tradeId]) {
          const trade = trades[tradeId];
          const { myOrganisation } = props;
          setOriginalTrade(JSON.parse(JSON.stringify(trade)));
          setAmendedTrade(JSON.parse(JSON.stringify(trade)));
          const enumerations = getTradeAmendEnumerations(trade, myOrganisation);
          setEnumerations(enumerations);
          const species = trade.assets[0].contract.underlying.species;
          const location = trade.assets[0].contract.underlying.location;
          const enums = getCartonNetWeightEnums({ species, location });
          setCartonNetWeightEnums(enums);
        }
      }
    }
  }, [tradeId]);
  useEffect(() => {
    const assetRows = getFieldsArray('order', ['breakdownRow', 'spot', 'fresh']);
    const fields = getFieldsArray('order', ['tradeAmendment', 'spot', 'fresh']);
    const fieldKeys = fields ? Object.keys(fields) : [];
    const fieldsTop = fieldKeys.filter((f) => fields[f].formTopSection);
    const fieldsBottom = fieldKeys.filter((f) => fields[f].formBottomSection);
    const fieldsRight = fieldKeys.filter((f) => fields[f].formRightSection);
    const fieldsLeft = fieldKeys.filter((f) => fields[f].formLeftSection);
    setFormFields(fields);
    setFormFieldsTop(fieldsTop);
    setFormFieldsBottom(fieldsBottom);
    setFormFieldsRight(fieldsRight);
    setFormFieldsLeft(fieldsLeft);
    setAssetRowFields(assetRows);
  }, []);

  const handleChange = (name) => (event) => {
    const newTrade = { ...amendedTrade };
    newTrade.assets.forEach((asset) => {
      if (name === 'consignee') {
        asset.contract[name] = event.target.value;
      } else {
        asset.contract.underlying[name] = event.target.value;
      }
    });
    setAmendedTrade(newTrade);
  };
  const handleDateChange = (name) => (dat) => {
    const date = dat.format('YYYY-MM-DD');
    const newTrade = { ...amendedTrade };
    newTrade.assets.forEach((asset) => {
      asset.contract[name] = date;
    });
    setAmendedTrade(newTrade);
  };
  const handleChangeAsset = (name, event, id) => {
    const newTrade = { ...amendedTrade };
    const value = event.target.value;

    newTrade.assets.forEach((asset) => {
      if (asset.id === id) {
        if (name === 'weightClass') {
          asset.contract.underlying[name] = value;
        } else if (name === 'packingWeight') {
          asset.contract[name] = value;
        } else if (name === 'volume') {
          asset[name] = Number(value);
          const cartonNetWeight = asset.contract.underlying.cartonNetWeight;
          const cartonValue = cartonNetWeightEnums[cartonNetWeight].numericalValue;
          const packingWeight = Number(event.target.value) * Number(cartonValue);
          asset.contract.packingWeight = String(packingWeight);
        } else {
          asset[name] = value;
        }
      }
    });
    setAmendedTrade(newTrade);
  };
  const addAssetRow = () => {
    if (amendedTrade) {
      if (amendedTrade.assets) {
        const newAssets = getNewAssets(amendedTrade.assets);
        const newAmendedTrade = { ...amendedTrade, assets: newAssets };
        setAmendedTrade(newAmendedTrade);
      }
    }
  };
  const getErrors = () => {};
  const deleteOrderRow = (id) => {
    const newTrade = { ...amendedTrade };
    const assets = [...amendedTrade.assets];
    if (assets.length > 1) {
      const index = assets.findIndex((i) => i.id === id);
      assets.splice(index, 1);
      newTrade.assets = assets;
      setAmendedTrade(newTrade);
    }
  };
  const sendAmendment = () => {
    const payload = {};
    payload.tradeId = amendedTrade.id;
    payload.body = amendedTrade;
    payload.callback = handleSend;
    props.onSend(payload);
  };
  const handleSend = (success) => {
    if (success) {
      setSnackbarSuccessOpen(true);
      props.onClose();
    } else {
      setSnackbarErrorOpen(true);
    }
  };
  const getFieldComponent = (field, order, fields, oldOrder) => {
    const fullAccessor = fields[field].fullAccessor.split('.');
    let value = order;
    let oldValue = oldOrder;
    for (let i = 0; i < fullAccessor.length; i++) {
      value = value[fullAccessor[i]];
      oldValue = oldValue[fullAccessor[i]];
    }
    const warning = oldValue !== value;
    switch (fields[field].component) {
      case 'text':
        return (
          <TextFieldFilled
            accessor={fields[field].accessor}
            key={`${field}-${order.key}`}
            value={value || ''}
            handleChange={handleChange}
            width={FIELD_WIDTH}
            warning={warning}
          ></TextFieldFilled>
        );
      case 'select':
        const values = enumerations[fields[field].accessor];
        /*  let values;
        if (
          fields[field].accessor === 'brandName' ||
          fields[field].accessor === 'processingPoint' ||
          fields[field].accessor === 'region'
        ) {
          values = [value];
        } else {
          values = enumerations[fields[field].accessor];
        } */
        return (
          <SelectField
            accessor={fields[field].accessor}
            key={`${field}-${order.key}`}
            value={value || ''}
            values={values}
            handleChange={handleChange}
            warning={warning}
            width={FIELD_WIDTH}
          />
        );
      case 'date':
        return (
          <DateField
            accessor={fields[field].accessor}
            value={value}
            disablePast={false}
            handleDateChange={handleDateChange}
            width={FIELD_WIDTH}
            warning={warning}
            key={`${field}-${order.key}`}
            clearable={fields[field].clearable}
          />
        );
      default:
        return null;
    }
  };

  return (
    <React.Fragment>
      <Modal open={open} onClose={props.onClose}>
        <div style={getModalStyle()} className={classes.paper}>
          <Typography className={classes.title}>
            <CustomText value="tradeAmendment" upperCase />
          </Typography>
          <ToolbarBottom>
            <ResetButton
              title={'reset'}
              onClick={() => setAmendedTrade(JSON.parse(JSON.stringify(originalTrade)))}
            />
          </ToolbarBottom>
          <div className={classes.container}>
            <div className={classes.sectionFlex}>
              {showDetails && (
                <React.Fragment>
                  <div style={{ flex: 1 }}>
                    <Typography className={classes.banner}>
                      <CustomText value="company" upperCase />
                    </Typography>

                    <Card style={{ margin: '0px 4px 4px', padding: '8px' }}>
                      {formFields &&
                        formFieldsTop.map((field) => {
                          if (formFields[field] && amendedTrade) {
                            const order = amendedTrade.assets[0];

                            const oldOrder = originalTrade.assets[0];

                            return getFieldComponent(field, order, formFields, oldOrder);
                          } else return null;
                        })}
                    </Card>
                  </div>
                  <div style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
                    <div style={{ flex: 1 }}>
                      <Typography className={classes.banner}>
                        <CustomText value="product" upperCase />
                      </Typography>

                      <Card style={{ margin: '0px 4px 4px', padding: '8px' }}>
                        {formFields &&
                          formFieldsLeft.map((field) => {
                            if (formFields[field] && amendedTrade) {
                              const order = amendedTrade.assets[0];
                              const oldOrder = originalTrade.assets[0];
                              return getFieldComponent(field, order, formFields, oldOrder);
                            } else return null;
                          })}
                      </Card>
                    </div>
                    <div style={{ flex: 1 }}>
                      <Typography className={classes.banner}>
                        {<CustomText value="freight" upperCase />}
                      </Typography>

                      <Card style={{ margin: '0px 4px 4px', padding: '8px' }}>
                        {formFields &&
                          formFieldsRight.map((field) => {
                            if (formFields[field] && amendedTrade) {
                              const order = amendedTrade.assets[0];
                              const oldOrder = originalTrade.assets[0];
                              return getFieldComponent(field, order, formFields, oldOrder);
                            } else return null;
                          })}
                      </Card>
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <Typography className={classes.banner}>
                      <CustomText value="offer" upperCase />
                    </Typography>

                    <Card style={{ margin: '0px 4px 4px', padding: '8px' }}>
                      {formFields &&
                        formFieldsBottom.map((field) => {
                          if (formFields[field] && amendedTrade) {
                            const order = amendedTrade.assets[0];
                            const oldOrder = originalTrade.assets[0];
                            return getFieldComponent(field, order, formFields, oldOrder);
                          } else return null;
                        })}
                    </Card>
                  </div>
                </React.Fragment>
              )}
              <div style={{ flex: 1 }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <ClearButton
                    icon="visibility"
                    title={'details'}
                    onClick={() => setShowDetails(!showDetails)}
                  />

                  <ClearButton icon="add" title={'addItem'} onClick={() => addAssetRow()} />
                </div>
                {amendedTrade && amendedTrade.assets && (
                  <div className={classes.orders}>
                    {amendedTrade.assets.map((row, index) => {
                      const oldRow = originalTrade.assets[index];
                      let warningWeightClass = true;
                      let warningPrice = true;
                      let warningVolume = true;
                      let warningPackingWeight = true;
                      const weightClass = row.contract.underlying.weightClass;
                      const price = row.price;
                      const volume = row.volume;
                      const packingWeight = row.contract.packingWeight;
                      const unit = row.contract.underlying.unit;
                      const currency = row.contract.underlying.currency;
                      if (oldRow) {
                        warningWeightClass = oldRow
                          ? oldRow.contract.underlying.weightClass !== weightClass
                          : true;
                        warningPrice = oldRow ? oldRow.price !== price : true;
                        warningVolume = oldRow ? oldRow.volume !== volume : true;
                        warningPackingWeight = oldRow
                          ? oldRow.contract.packingWeight !== packingWeight
                          : true;
                      }
                      return (
                        <Card
                          key={row.id}
                          style={{
                            margin: '4px',
                            padding: '8px',
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                        >
                          <div className={classes.containerItems} style={{ flex: 1 }}>
                            <SelectField
                              accessor="weightClass"
                              displayName="weightClass"
                              value={weightClass}
                              values={enumerations['weightClass']}
                              handleChange={(name) => (event) => {
                                handleChangeAsset(name, event, row.id);
                              }}
                              warning={warningWeightClass}
                              width={FIELD_WIDTH}
                            />
                            <NumberFieldCustom
                              accessor="volume"
                              key={`${'volume'}-${row.id}`}
                              displayName={'Volume'}
                              value={volume}
                              handleChange={(name) => (event) => {
                                handleChangeAsset(name, event, row.id);
                              }}
                              error={getErrors('volume')}
                              warning={warningVolume}
                              adornment={'cartons'}
                              width={FIELD_WIDTH}
                              //step={formFields['volume'].step}
                            />
                            <NumberFieldCustom
                              accessor="price"
                              key={`${'price'}-${row.id}`}
                              displayName={'Price'}
                              value={row.price}
                              handleChange={(name) => (event) => {
                                handleChangeAsset(name, event, row.id);
                              }}
                              error={getErrors('price')}
                              adornment={currency}
                              warning={warningPrice}
                              width={FIELD_WIDTH}
                              //step={formFields['price'].step}
                            />
                            <NumberFieldCustom
                              accessor="packingWeight"
                              key={`${'packingWeight'}-${row.id}`}
                              displayName={'PackingWeight'}
                              value={packingWeight}
                              handleChange={(name) => (event) => {
                                handleChangeAsset(name, event, row.id);
                              }}
                              error={getErrors('price')}
                              adornment={unit}
                              warning={warningPackingWeight}
                              width={FIELD_WIDTH}
                              //step={formFields['price'].step}
                            />
                          </div>
                          {!oldRow && (
                            <div className={classes.containerItems}>
                              <Tooltip
                                title={<CustomText value="deleteOrder" upperCase />}
                                aria-label={'deleteOrder'}
                              >
                                <IconButton onClick={() => deleteOrderRow(row.id)}>
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </div>
                          )}
                        </Card>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={classes.totals}>
            <TotalsRow orderTradeArray={[amendedTrade]}></TotalsRow>
          </div>
          <ToolbarBottom>
            <CancelButton onClick={props.onClose} title={'close'} />
            <ConfirmButton onClick={sendAmendment} title={'send'} />
          </ToolbarBottom>
        </div>
      </Modal>
      <SnackbarCustom
        open={snackbarSuccessOpen}
        onClose={() => setSnackbarSuccessOpen(false)}
        messageType="success"
        message="tradeAmendmentSuccess"
      ></SnackbarCustom>
      <SnackbarCustom
        open={snackbarErrorOpen}
        onClose={() => setSnackbarErrorOpen(false)}
        messageType="error"
        message="tradeAmendmentFail"
      ></SnackbarCustom>
    </React.Fragment>
  );
};
TradeAmendment.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    open: isAmendTradeOpen(state) || false,
    trades: getAllObject(state),
    tradeId: getAmendTradeId(state),
    myOrganisation: getMyOrganisation(state),
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onClose: () => {
      dispatch(createAction(AMEND_TRADE_FORM_OPEN, { open: false, tradeId: null }));
    },
    onSend: (payload) => {
      dispatch(createAction(TRADE_AMENDMENT, payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TradeAmendment));
