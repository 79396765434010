import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';

import teal from '@material-ui/core/colors/teal';
import lightBlue from '@material-ui/core/colors/lightBlue';
import red from '@material-ui/core/colors/red';
import { darken } from '@material-ui/core/styles/colorManipulator';
import UserAvatar from '../../../commons/components/userAvatar';
import { formatDateWithDay } from '../../../commons/config/formatters';
import { directions, visibilities } from '../../../commons/models/constants';
import { getOrganisationShortNameFromId } from '../../../commons/utils/functions';

import { getOrganisations, getActiveUserOrganisation } from '../selectors';
import { getOrganisationsObject, getUsersObject, getActiveUser } from '../../orders/selectors';
import { ConfirmButton, ClearButton, CancelButton } from '../../../commons/components/buttons';
import CustomText from '../../../commons/components/formFields/customText';
const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    borderRadius: '4px',
    padding: theme.spacing(),
    position: 'relative',
    margin: theme.spacing(),
  },
  containerBuy: {
    border: 'solid 3px',
    borderColor: lightBlue[300],
  },
  containerSell: {
    border: 'solid 3px',
    borderColor: teal[300],
  },
  containerSelected: {
    border: 'solid 3px',
    borderColor: theme.palette.amber,
    backgroundColor: theme.palette.background.activeTab,
  },
  containerOpaque: {
    opacity: 1,
    display: 'flex',
    //marginTop: theme.spacing(),
    //marginBottom: theme.spacing(),
    flexDirection: 'row',
    justifyContent: 'center',
    border: 'solid 1px',
    borderColor: theme.palette.divider,
    backgroundColor: red[500],
    '&:hover': {
      opacity: 0.85,
      cursor: 'pointer',
    },
  },
  containerItem: {
    display: 'flex',
    fontSize: theme.typography.fontSize,
    padding: '0px 16px',
  },
  containerItemOrg: {
    fontSize: theme.typography.fontSize * 0.9,
    color: theme.palette.teal,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    flex: 1,
  },
  containerContentBuy: {
    backgroundColor: 'transparent',
  },
  containerContentSell: {
    backgroundColor: 'transparent',
  },
  containerContentEnded: {
    backgroundColor: darken(red[500], 0.5),
  },

  containerContentItem: {
    display: 'flex',
    justifyItems: 'center',
    alignItems: 'center',
  },
  containerRecipients: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',

    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    minHeight: '24px',
    color: '#fff',
  },
  recipientsBuy: {
    backgroundColor: 'transparent',
  },
  recipientsSell: {
    backgroundColor: 'transparent',
  },
  recipientsEnded: {
    backgroundColor: darken(red[500], 0.2),
  },

  containerFooterBuy: {
    backgroundColor: 'transparent',

    color: '#fff',
  },
  containerFooterSell: {
    backgroundColor: 'transparent',
    maxHeight: '36px',
    color: '#fff',
  },
  containerFooterEnded: {
    backgroundColor: red[500],

    color: '#fff',
  },

  direction: {
    color: teal[500],
    fontWeight: 'bold',
  },
  statuss: {
    padding: theme.spacing() / 2,
    paddingLeft: theme.spacing(),
    color: '#fff',
  },
  button: {
    borderRadius: 0,
    borderTopLeftRadius: '4px',
    borderBottomRightRadius: '2px',
    padding: '0px 16px',
    fontSize: theme.typography.fontSize * 0.85,
  },

  buttonColorBuy: {
    backgroundColor: lightBlue[300],

    '&:hover': {
      backgroundColor: lightBlue[400],
    },
  },
  buttonColorSell: {
    backgroundColor: teal[300],

    '&:hover': {
      backgroundColor: teal[400],
    },
  },
  buttonColorSelected: {
    backgroundColor: theme.palette.amber,

    '&:hover': {
      backgroundColor: theme.palette.amber,
    },
  },
  timestampContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },

  timestamp: {
    paddingRight: theme.spacing(),
    color: '#fff',
    fontSize: '12px',
    textAlign: 'center',
  },
});

const getRecipients = (selectedOrganisationsIds, organisations, visibility) => {
  const list = [];
  const orgs = [...organisations];
  if (visibility === visibilities.SELECTED_ORGANISATIONS) {
    selectedOrganisationsIds.forEach((r) => {
      let shortName = r.shortName || null;
      if (shortName === null) {
        shortName = getOrganisationShortNameFromId(r, orgs);
      }
      list.push(shortName);
    });
  } else {
    return visibility;
  }

  list.sort();
  const listString = list.join(', ');
  return listString;
};

const getCounterparty = (selectedOrganisationsIds, organisations, isCompanyOrder, rfqOwnerId) => {
  const orgObject = {
    id: null,
    name: '',
    shortName: '',
  };
  const orgs = { ...organisations };
  let orgId = null;
  if (isCompanyOrder) {
    orgId = selectedOrganisationsIds[0];
  } else {
    orgId = rfqOwnerId;
  }
  const org = orgId !== null ? orgs[orgId] : null;

  if (org) {
    orgObject.id = org.id;
    orgObject.name = org.name;
    orgObject.shortName = org.shortName;
  }
  return orgObject;
};
const getData = (data, organisations, isCompanyOrder) => {
  if (Object.prototype.toString.call(data.contract) === '[object Object]') {
    if (Object.prototype.toString.call(data.contract.underlying) === '[object Object]') {
      const selectedOrganisationsIds = data.selectedOrganisationsIds || [];
      const rfqOwnerId = data.brokerOrganisationId || '';
      const counterparty = getCounterparty(
        selectedOrganisationsIds,
        organisations,
        isCompanyOrder,
        rfqOwnerId,
      );
      const deliveryDate = data.contract.deliveryDate ? data.contract.deliveryDate.to : null;
      return {
        /*  species: (function (word) {
          const words = word.match(/([A-Z]?[^A-Z]*)/g);
          words[0] = words[0][0].toUpperCase() + words[0].substring(1);
          return words.join(' ');
        })(data.contract.underlying.product), */
        species: data.contract.underlying.species,
        volume: data.volume,
        deliveryDate: formatDateWithDay(deliveryDate),

        organisation: counterparty.name,
        organisationShortName: counterparty.shortName,
        orgId: counterparty.id,
      };
    }
  }
  return {};
};

const Tab = (props) => {
  const {
    classes,
    organisations,
    organisationsObject,

    data,

    user,
    publishIsDisabled,
  } = props;
  const isCompanyOrder = data.brokerOrganisationId === user.organisationId;

  const status = data.status === 'ACTIVE' ? 'IN PROGRESS' : 'ENDED';
  const selectedOrganisationsIds = data.selectedOrganisationsIds || [];

  const visibility = data.visibility;

  const callOutData = getData(data, organisationsObject, isCompanyOrder);
  const containerClass =
    status === 'ENDED'
      ? classes.containerOpaque
      : data.direction === directions.BUY
      ? classes.containerSelected
      : classes.containerSelected;

  return (
    <div className={`${containerClass} ${classes.container}`}>
      <div className={clsx(classes.containerItem, classes.containerItemOrg)}>
        <div
          style={{ paddingRight: '8px' }}
        >{`${callOutData.organisation} | ${callOutData.organisationShortName}`}</div>
        <UserAvatar organisationId={callOutData.orgId} />
      </div>

      <div className={classes.containerItem}>
        <CustomText value={callOutData.species} />
      </div>
      <div className={classes.containerItem}>{`${callOutData.deliveryDate}`}</div>
      <div className={classes.containerItem}>
        {`${callOutData.volume} `}
        <CustomText value="cartons" upperCase />
      </div>
      <div className={clsx(classes.containerItem, classes.buttonContainer)}>
        {isCompanyOrder && <CancelButton title="endRfq" onClick={props.endRfq}></CancelButton>}
        <ClearButton
          title={'offerDetails'}
          onClick={props.showDetails}
          iconStyle={{ color: '#fff' }}
          style={{ borderColor: 'rgba(255,255,255,.1', color: '#fff' }}
        ></ClearButton>
        {isCompanyOrder && (
          <ConfirmButton
            title={'publish'}
            icon="cancel"
            onClick={props.addOrder}
            disabled={publishIsDisabled}
          ></ConfirmButton>
        )}
      </div>
    </div>
  );
};

Tab.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    organisations: getOrganisations(state), // state.rooms.organisations,
    myOrg: getActiveUserOrganisation(state), // state.logins.organisation,
    organisationsObject: getOrganisationsObject(state),
    usersObject: getUsersObject(state),
    user: getActiveUser(state),
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Tab));
