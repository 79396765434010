import { takeEvery, call, put, select, all } from 'redux-saga/effects';

import {
  GET_TRADES_SUCCESS,
  GET_TRADES_ERROR,
  GET_TRADE_SUCCESS,
  GET_TRADE_ERROR,
  GET_TRADES,
  RESET_TRADES,
  GET_TRADE,
  INSERT_TRADES_FROM_SOCKET,
  INSERT_TRADES_FROM_SOCKET_SUCCESS,
  INSERT_TRADES_FROM_SOCKET_ERROR,
  TRADE_DETAILS_OPEN,
  TRADE_DETAILS_OPEN_SUCCESS,
  TRADE_DETAILS_OPEN_ERROR,
  POST_TRADE_FORM_OPEN,
  POST_TRADE_FORM_OPEN_SUCCESS,
  POST_TRADE_FORM_OPEN_ERROR,
  AMEND_TRADE_FORM_OPEN,
  AMEND_TRADE_FORM_OPEN_SUCCESS,
  AMEND_TRADE_FORM_OPEN_ERROR,
  UPDATE_TRADES_FROM_SOCKET,
  UPDATE_TRADES_FROM_SOCKET_SUCCESS,
  UPDATE_TRADES_FROM_SOCKET_ERROR,
  UPDATE_END_RFQ_PROMPT,
  UPDATE_END_RFQ_PROMPT_SUCCESS,
  UPDATE_END_RFQ_PROMPT_ERROR,
  TRADE_CANCELLATION,
  TRADE_AMENDMENT,
} from '../constants';

import {
  getTrades,
  getTrade,
  resetTrades,
  insertTrades,
  tradeDetailsOpen,
  updateTrades,
  updateEndRfqPrompt,
} from '../actions';

import api from '../api';

import auth from '../../auth';
import fileUpload from '../../fileUpload';
import { SOCKET_RECONNECTED } from '../../../modules/socket/actionTypes';
import {
  getLastCreatedTime,
  getAllObject,
  getPostTradeFormData,
  getPostTradeForm,
} from '../selectors';
import { fields } from '../config';
import { createPostTradeFormData } from '../utils/functions';

/**
 *
 * @param {*} action
 */
export function* getAll() {
  try {
    // TODO: move into interceptor
    const token = yield call(auth.selectors.getToken);

    const options = {
      token,
      params: {},
    };

    const lastCreatedTime = yield select(getLastCreatedTime);

    if (lastCreatedTime) {
      options.params.createdTimeFrom = lastCreatedTime;
    }

    const trades = yield call(api.getAll, options);

    const payload = {
      items: trades,
    };

    const meta = {
      receivedAt: new Date(),
    };

    yield put(getTrades(GET_TRADES_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    const checkedError = yield call(auth.sagas.checkError, error); // TODO: sync operation. For asyn use fork

    if (!checkedError) {
      yield put(getTrades(GET_TRADES_ERROR, { error }));
    }
  }
}

/**
 *
 * @param {*} action
 */
function* get() {
  try {
    const trade = yield call(api.get);

    const payload = {
      items: [trade],
    };

    const meta = {
      receivedAt: new Date(),
    };

    yield put(getTrade(GET_TRADE_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    const checkedError = yield call(auth.sagas.checkError, error); // TODO: sync operation. For asyn use fork

    if (!checkedError) {
      yield put(getTrade(GET_TRADE_ERROR, { error }));
    }
  }
}

/**
 *
 * @param {*} action
 */
function* insertFromSocket(action) {
  try {
    const {
      payload: { items },
    } = action;
    const payload = {
      items,
    };
    console.log('inserting trades from socket', items);
    const meta = {
      receivedAt: new Date(),
    };

    yield put(insertTrades(INSERT_TRADES_FROM_SOCKET_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    yield put(insertTrades(INSERT_TRADES_FROM_SOCKET_ERROR, { error }));
  }
}

function* updateFromSocket(action) {
  try {
    const {
      payload: { items },
    } = action;

    const payload = {
      items,
    };
    console.log('uploading trades from socket', items);
    const meta = {
      receivedAt: new Date(),
    };

    yield put(updateTrades(UPDATE_TRADES_FROM_SOCKET_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    yield put(updateTrades(UPDATE_TRADES_FROM_SOCKET_ERROR, { error }));
  }
}

/**
 *
 * @param {*} action
 */
function* setTradeDetailsOpen(action) {
  try {
    const { payload } = action;

    const meta = {
      receivedAt: new Date(),
    };
    yield put(tradeDetailsOpen(TRADE_DETAILS_OPEN_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    yield put(tradeDetailsOpen(TRADE_DETAILS_OPEN_ERROR, { error }));
  }
}
/**
 *
 * @param {*} action
 */
function* setPostTradeFormOpen(action) {
  const payload = {};
  const meta = {
    receivedAt: new Date(),
  };
  try {
    const { payload: actionPayload } = action;

    if (actionPayload.type === 'open') {
      const trades = yield select(getAllObject);
      const trade = trades[actionPayload.tradeId];
      const user = yield select(auth.selectors.getServerUser);
      const formType =
        user.organisationId === trade.buyBrokerOrganisationId ||
        user.organisationId === trade.buyBeneficiaryOrganisationId
          ? 'buyerData'
          : 'sellerData';
      const selected = [];
      if (trade.buyBeneficiaryOrganisationId) {
        if (user.organisationId !== trade.buyBeneficiaryOrganisationId)
          selected.push(trade.buyBeneficiaryOrganisationId);
      }
      if (trade.sellBeneficiaryOrganisationId) {
        if (user.organisationId !== trade.sellBeneficiaryOrganisationId)
          selected.push(trade.sellBeneficiaryOrganisationId);
      }
      if (trade.buyBrokerOrganisationId) {
        if (user.organisationId !== trade.buyBrokerOrganisationId)
          selected.push(trade.buyBrokerOrganisationId);
      }
      if (trade.sellBrokerOrganisationId) {
        if (user.organisationId !== trade.sellBrokerOrganisationId)
          selected.push(trade.sellBrokerOrganisationId);
      }
      const selectedIds = [...new Set(selected)];

      const data = createPostTradeFormData(trade, formType);

      payload.open = actionPayload.open;
      payload.formType = formType;
      payload.tradeId = actionPayload.tradeId;
      payload.selectedIds = selectedIds;
      payload.data = data;
      yield put(fileUpload.actions.getFiles(fileUpload.constants.GET_FILES));
    } else if (actionPayload.type === 'close') {
      payload.tradeId = null;
      payload.data = {};
      payload.open = actionPayload.open;
    } else if (actionPayload.type === 'update') {
      const data = yield select(getPostTradeFormData);
      data[actionPayload.name] = actionPayload.value;

      payload.data = data;
    } else if (actionPayload.type === 'delete') {
      const data = yield select(getPostTradeFormData);
      const newData = { ...data };
      delete newData[actionPayload.name];
      payload.data = newData;
    } else if (actionPayload.type === 'send') {
      const form = yield select(getPostTradeForm);
      const { formType, data } = form;
      const token = yield call(auth.selectors.getToken);
      const config = fields[formType];

      const body = {
        fileIds: [],
      };
      if (data.files) {
        data.files.forEach((file) => {
          body.fileIds.push(file.id);
        });
      }
      Object.keys(data).forEach((d) => {
        if (d !== 'files') {
          body[d] = data[d];
        }
      });

      const items = {
        body,
        token,
      };

      console.log('trades:saga:postTradeForm', JSON.stringify(body));
      console.log('trades:saga:postTradeForm:tradeId', form.tradeId);

      const updated = yield call(api.update, form.tradeId, items, form.formType);

      if (action.payload.callback) {
        action.payload.callback(true);
      }

      yield put(updateTrades(UPDATE_TRADES_FROM_SOCKET_SUCCESS, { items: [updated] }, meta));
      payload.tradeId = null;
      payload.data = {};
      payload.open = false;
    }

    yield put(tradeDetailsOpen(POST_TRADE_FORM_OPEN_SUCCESS, payload, meta));
  } catch (error) {
    if (action.payload.callback) {
      action.payload.callback(false);
    }
    console.error('POST TRADE FORM ERROR', error);
    yield put(tradeDetailsOpen(POST_TRADE_FORM_OPEN_ERROR, { error }));
  }
}
/**
 *
 * @param {*} action
 */
function* setAmendTradeFormOpen(action) {
  const payload = {};
  const meta = {
    receivedAt: new Date(),
  };
  try {
    const { payload: actionPayload } = action;

    if (actionPayload.type === 'open') {
      payload.open = actionPayload.open;
      payload.tradeId = actionPayload.tradeId;
    } else if (actionPayload.type === 'close') {
      payload.tradeId = null;
      payload.open = actionPayload.open;
    }

    yield put(tradeDetailsOpen(AMEND_TRADE_FORM_OPEN_SUCCESS, payload, meta));
  } catch (error) {
    console.error('POST TRADE FORM ERROR', error);
    yield put(tradeDetailsOpen(AMEND_TRADE_FORM_OPEN_ERROR, { error }));
  }
}
/**
 *
 * @param {*} action
 */

function* updateTradeCancellation(action) {
  try {
    const payload = action.payload;
    const token = yield call(auth.selectors.getToken);
    const data = {
      token,
      body: payload.body,
    };

    const response = yield call(api.updateCancel, payload.tradeId, data);
  } catch {}
}
/**
 *
 * @param {*} action
 */

function* updateTradeAmendment(action) {
  try {
    const payload = action.payload;
    const token = yield call(auth.selectors.getToken);
    const data = {
      token,
      body: payload.body,
    };

    console.log('trades:saga:amendTradeForm', JSON.stringify(payload.body));
    console.log('trades:saga:amendTradeForm:tradeId', payload.tradeId);
    const response = yield call(api.updateAmendment, payload.tradeId, data);

    if (action.payload.callback) action.payload.callback(true);
  } catch {
    if (action.payload.callback) action.payload.callback(false);
  }
}
/**
 *
 * @param {*} action
 */

function* updateEndRfq(action) {
  try {
    const payload = {};

    const meta = {
      receivedAt: new Date(),
    };

    yield put(updateEndRfqPrompt(UPDATE_END_RFQ_PROMPT_SUCCESS, payload, meta));
  } catch (error) {
    console.error(error);
    yield put(updateEndRfqPrompt(UPDATE_END_RFQ_PROMPT_ERROR, { error }));
  }
}

/**
 *
 * @param {*} action
 */
function* reset() {
  yield put(resetTrades(RESET_TRADES));
}

export function* watchGetTrades() {
  yield takeEvery(GET_TRADES, getAll, {});
}

export function* watchGetTrade() {
  yield takeEvery(GET_TRADE, get);
}

export function* watchInsertTradesFromSocket() {
  yield takeEvery(INSERT_TRADES_FROM_SOCKET, insertFromSocket);
}

export function* watchUpdateTradesFromSocket() {
  yield takeEvery(UPDATE_TRADES_FROM_SOCKET, updateFromSocket);
}

export function* watchResetTrades() {
  yield takeEvery(RESET_TRADES, reset);
}

export function* watchTradeDetailsOpen() {
  yield takeEvery(TRADE_DETAILS_OPEN, setTradeDetailsOpen);
}

export function* watchPostTradeFormOpen() {
  yield takeEvery(POST_TRADE_FORM_OPEN, setPostTradeFormOpen);
}

export function* watchAmendTradeFormOpen() {
  yield takeEvery(AMEND_TRADE_FORM_OPEN, setAmendTradeFormOpen);
}

export function* watchUpdateEndRfqPrompt() {
  yield takeEvery(UPDATE_END_RFQ_PROMPT, updateEndRfq);
}
export function* watchTradeCancellation() {
  yield takeEvery(TRADE_CANCELLATION, updateTradeCancellation);
}

export function* watchTradeAmendment() {
  yield takeEvery(TRADE_AMENDMENT, updateTradeAmendment);
}

function signOutSuccess() {}

export function* watchSignOutSuccess() {
  yield takeEvery(auth.constants.SIGN_OUT_SUCCESS, signOutSuccess);
}

function* socketReconnected() {
  yield call(getAll);
}

export function* watchSocketReconnected() {
  yield takeEvery(SOCKET_RECONNECTED, socketReconnected);
}
