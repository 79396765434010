import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  SIGN_IN_NEW_PASSWORD_VALIDATION_SENT,
  BACK_TO_SIGN_IN,
} from '../../../modules/auth/constants';
import { createAction } from '../../../commons/actions';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import TextBox from '../textBox';
import Button from '../button';
import { getStatus, getError } from '../../../modules/auth/selectors';

import { passwordValidation, passwordValidationMessage } from '../validators';
import clsx from 'clsx';
const styles = (theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
    height: '100vh',
  },
  forgotPasswordButton: {
    color: 'var(--alternate-dark-color)',
    border: 'none',
    backgroundColor: 'transparent',
    fontSize: '16px',
    cursor: 'pointer',
  },
  errorMessageContainer: {
    height: '50px',
    margin: '8px auto',
    maxWidth: '216px',
  },
  errorHidden: {
    display: 'none',
  },
  errorVisible: {
    display: 'inline',
    overflowWrap: 'normal',
    fontFamily: 'sans-serif',
    color: 'var(--text-error-color)',
  },
});

const RequireNewPasswordValidation = (props) => {
  const { classes, backToSignIn } = props;
  const [validationCode, setValidationCode] = useState(null);
  const [username, setUsername] = useState(null);
  const [newPassword, setInitialPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [focus, setFocus] = useState(null);
  const [inputError, setInputError] = useState(null);

  const handleSubmit = () => {
    if (!validationCode) {
      setInputError('Validation code required');
    } else if (
      passwordValidation(newPassword) &&
      passwordValidation(confirmPassword) &&
      newPassword === confirmPassword
    ) {
      const payload = {
        username: username,
        code: validationCode,
        password: newPassword,
      };

      props.createNewPasswordValidation(payload);
    } else {
      console.log();
      if (inputError === null) {
        setInputError('Passwords do not match');
      }
    }
  };

  const handleChange = (id) => (event) => {
    console.log('id', id);
    switch (id) {
      case 'validationCode':
        setValidationCode(event.target.value);
        break;

      case 'username':
        setUsername(event.target.value);
        break;

      case 'newPassword':
        setInitialPassword(event.target.value);
        setInputError(passwordValidationMessage(event.target.value));

        break;

      default:
        setConfirmPassword(event.target.value);
        setInputError(passwordValidationMessage(event.target.value));
    }
  };

  const onFocus = () => {
    setFocus(true);
  };

  const onBlur = () => {
    setFocus(false);
  };

  return (
    <div className={classes.main}>
      <div className={classes.container}>
        <div className={classes.loginBackground}>
          <Typography>Enter Validation Code and New Password</Typography>
          <div style={{ maxWidth: '400px', margin: 'auto' }}>
            <TextBox
              icon="verified-user"
              id="validationCode"
              placeholder="Validation Code"
              hover={!focus}
              onBlur={() => onBlur()}
              onFocus={() => onFocus()}
              onChange={handleChange}
              autocomplete="new-password"
            ></TextBox>
            <TextBox
              icon="person"
              id="username"
              placeholder="Email"
              hover={!focus}
              onBlur={() => onBlur()}
              onFocus={() => onFocus()}
              onChange={handleChange}
              autocomplete="new-password"
            ></TextBox>
            <TextBox
              icon="lock"
              id="newPassword"
              placeholder="New Password"
              hover={!focus}
              onBlur={() => onBlur()}
              onFocus={() => onFocus()}
              onChange={handleChange}
              visibility
              autocomplete="new-password"
            ></TextBox>
            <TextBox
              icon="lock"
              id="confirmPassword"
              placeholder="ConfirmPassword"
              hover={!focus}
              onBlur={() => onBlur()}
              onFocus={() => onFocus()}
              onChange={handleChange}
              visibility
              autocomplete="new-password"
            ></TextBox>
            <Button onClick={handleSubmit} title="SUBMIT" />
            <button className={classes.forgotPasswordButton} onClick={props.signIn}>
              Back to Login
            </button>
            <div className={classes.errorMessageContainer}>
              <div
                className={clsx({
                  [classes.errorVisible]: true,
                  [classes.errorHidden]: inputError,
                })}
              >
                {inputError ? inputError : ''}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    error: getError(state),
    status: getStatus(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  createNewPasswordValidation: (payload) =>
    dispatch(createAction(SIGN_IN_NEW_PASSWORD_VALIDATION_SENT, payload)),
  signIn: () => dispatch(createAction(BACK_TO_SIGN_IN)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(RequireNewPasswordValidation),
);
