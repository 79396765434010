import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { red } from '@material-ui/core/colors';
import { Icon, IconButton } from '@material-ui/core';

const style = (theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(),
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:first-child': {
      borderTop: `1px solid ${theme.palette.divider}`,
    },
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(),
    borderRadius: '2px',
    overflow: 'hidden',
  },
  itemRight: {
    //flex: 1,
    padding: '0px 2px',
    textAlign: 'right',
  },
  itemCenter: {
    flex: 1,
    padding: '0px 4px',
    textAlign: 'center',
  },
  itemFile: {
    flex: 2,

    padding: '0px 2px',
  },
  typeAndSize: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  itemFileName: {
    fontSize: '16px',
  },
  itemFileType: {
    color: theme.palette.text.secondary,
  },
  itemFileSize: {
    color: theme.palette.text.disabled,
    padding: '0 4px',
  },

  thumbContainer: {
    height: '50px',
    width: '50px',
    overflow: 'hidden',
  },
  thumb: {
    height: '90px',
  },
  pdfIcon: {
    fontSize: '48px',
    color: red[500],
  },
  deleteIcon: {
    cursor: 'pointer',
    padding: theme.spacing(),
  },
});

const FileRow = (props) => {
  const { classes, file, removeFile, showFile, allFieldValues = {} } = props;
  console.log('file', file);
  /*  const _fileType = allFieldValues[file.documentType]
    ? allFieldValues[file.documentType].displayName
    : file.documentType; */
  const _bytes = allFieldValues.bytes ? allFieldValues.bytes.displayName : 'bytes';
  const _fileType = allFieldValues[file.type]
    ? allFieldValues[file.type].displayName.toUpperCase()
    : file.type;
  return (
    <div className={classes.container}>
      <div className={classes.item}>
        <div className={classes.thumbContainer}>
          {file.type === 'application/pdf' ? (
            <i className={`fa fa-file-pdf-o ${classes.pdfIcon}`}></i>
          ) : (
            <img className={classes.thumb} src={file.preview} alt="doc" />
          )}
        </div>
      </div>
      {/*   <div className={classes.itemCenter}>
        <TextInputField
          accessor={props.index}
          displayName="Document Name (optional)"
          value={file.documentName || ''}
          handleChange={props.handleDocumentNameChange}
          fullWidth
        />
      </div> */}
      <div className={classes.itemCenter}>{file.documentType}</div>
      <div className={classes.itemFile}>
        <div className={classes.itemFileName}>{file.name}</div>
        <div className={classes.typeAndSize}>
          <div className={classes.itemFileType}>{_fileType}</div>
          <div className={classes.itemFileSize}>
            {file.size} {_bytes}
          </div>
        </div>
      </div>
      <div className={classes.itemRight}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <IconButton onClick={showFile} aria-label="Visibility">
            <Icon>visibility</Icon>
          </IconButton>
          <IconButton onClick={removeFile} aria-label="Visibility">
            <Icon>delete</Icon>
          </IconButton>

          {/*    <i className={`material-icons ${classes.deleteIcon}`} onClick={showFile}>
            
          </i>
          <i className={`material-icons ${classes.deleteIcon}`} onClick={removeFile}>
            delete
          </i> */}
        </div>
      </div>
    </div>
  );
};
FileRow.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(FileRow);
