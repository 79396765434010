import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import { Modal, Typography, Snackbar, Card } from '@material-ui/core';

import MuiAlert from '@material-ui/lab/Alert';
import { checkPrice, checkVolume } from '../../../../commons/config/formatters';
import { directions, visibilities, status } from '../../../../commons/models/constants';

import ResponseFormRow from './ResponseFormRow';
import TotalsRow from '../TotalsRow';

import ToolbarBottom from '../../../../commons/components/toolbarBottom';

import { CancelButton, ConfirmButton, ClearButton } from '../../../../commons/components/buttons';
import DirectionField from '../../../../commons/components/formFields/DirectionField';
import SelectField from '../../../../commons/components/formFields/SelectField';
import SelectFieldOrgs from '../../../../commons/components/formFields/SelectFieldOrgs';
import MultipleSelectFieldOrgs from '../../../../commons/components/formFields/multipleSelectFieldOrgs';
import DateTimeField from '../../../../commons/components/formFields/dateTimeField';
import DateField from '../../../../commons/components/formFields/dateField';
import TextFieldFilled from '../../../../commons/components/formFields/textFieldFilled';
import SwitchField from '../../../../commons/components/formFields/switchField';
import CustomText from '../../../../commons/components/formFields/customText';

import {
  isOpenFormResponse,
  getOrders,
  getOrganisations,
  getRfqResponseForm,
  getActiveUser,
  getOrganisationsObject,
  getOrdersResponseForm,
  getDefaultOrdersResponseForm,
  getActiveUserOrganisation,
  getOrdersResponseFormEnumerations,
  getResponseFormFields,
  getOrderToCancelResponseForm,
  getMyOrganisation,
} from '../../selectors';

import { updateForm } from '../../actions';
import { UPDATE_RESPONSE_FORM } from '../../constants';

import orders from '../../../orders';
import schemas from '../../../schemas';

const ERROR_RECIPIENT = 'At least one organisation must be selected.';
const FIELD_WIDTH = '150px';
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = (theme) => ({
  paper: {
    position: 'absolute',
    width: '80vw',
    maxHeight: '90vh',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  paperNotRfqOwner: {
    position: 'absolute',
    maxHeight: '90vh',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflowY: 'scroll',
    backgroundColor: theme.palette.background.primary,
  },

  section: {
    marginTop: theme.spacing(0.5),
    //backgroundColor: theme.palette.primary.row,
  },

  sectionFlex: {
    marginTop: theme.spacing(0.5),
    //flex: 1,
    //display: "flex",
    //flexDirection: "column",
    //overflow: "auto"
  },
  subsection: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(0.5),
    //backgroundColor: theme.palette.primary.row,
  },
  subsectionWrap: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(0.5),
    flexFlow: 'row wrap',
    alignItems: 'center',
  },
  totals: {
    display: 'flex',
    flexDirection: 'column',
  },

  orders: {
    //backgroundColor: theme.palette.primary.row,
    //padding: theme.spacing(1),
    flex: 1,
    //overflowY: 'scroll',
  },

  banner: {
    //backgroundColor: theme.palette.primary.dark,
    //padding: theme.spacing(0.5),
    color: theme.palette.text.disabled,
    fontSize: theme.typography.fontSize,
  },

  title: { fontSize: theme.fontSize * 1.5 },
  comments: {
    padding: '8x !important',
    margin: '2.47px',
  },
});

// TODO: Extract all functionality to saga ans store. REFACTOR!!!! CRITICAL!!!!!
class ResponseForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      expandedDefault: false,
      expandedOrders: false,
      textMessage: '',
      timeInForce: null,
      statusChecked: true,
      showDetails: false,
      snackbarSuccessOpen: false,
      snackBarErrorOpen: false,
    };
  }

  expandSection = (section) => {
    if (section === 'defaultOrders') {
      this.setState({ expandedDefault: !this.state.expandedDefault });
    }
    if (section === 'orders') {
      this.setState({ expandedOrders: !this.state.expandedOrders });
    }
  };

  getDirection = () => {
    const isRfqRequestor =
      this.props.activeRfq.brokerOrganisationId === this.props.user.organisationId;
    const direction =
      isRfqRequestor === true
        ? this.props.activeRfq.direction === directions.BUY
          ? directions.BUY
          : directions.SELL
        : this.props.activeRfq.direction === directions.BUY
        ? directions.SELL
        : directions.BUY;
    return direction;
  };

  getOrganisations = () => {
    const { activeRfq, organisationsObject, organisations, userOrganisation } = this.props;
    let orgs = [];

    if (activeRfq) {
      if (activeRfq.visibility === 'SELECTED_ORGANISATIONS') {
        activeRfq.selectedOrganisationsIds.forEach((id) => {
          orgs.push(organisationsObject[id]);
        });
      } else if (activeRfq.visibility === 'TRADING_PARTNERS') {
        const tradingPartners = userOrganisation.tradingPartnersIds || [];
        tradingPartners.forEach((id) => {
          orgs.push(organisationsObject[id]);
        });
      } else {
        orgs = organisations;
      }
    }

    return orgs;
  };

  addOrderRow = (actionType = 'addRow') => {
    const payload = {
      type: 'response',
      action: actionType,
    };
    this.props.updateResponseForm(payload);
  };

  deleteOrderRow = (key, actionType = 'deleteRow') => {
    const payload = {
      type: 'response',
      action: actionType,
      key,
    };
    this.props.updateResponseForm(payload);
  };
  copyOrderRow = (key, actionType = 'copyRow') => {
    const payload = {
      type: 'response',
      action: actionType,
      key,
    };
    this.props.updateResponseForm(payload);
  };

  handleTextMessage = (textMessage) => {
    this.setState({ textMessage });
  };
  handleTimeInForce = (name) => (event) => {
    if (name === 'validity') {
      const val = this.state.timeInForce === null ? moment().format('YYYY-MM-DDTHH:mm') : null;
      this.setState({ timeInForce: val });
    } else {
      this.setState({ timeInForce: event.target.value });
    }
  };
  handleChange = (name) => (event) => {
    const payload = {
      type: 'response',
      action: 'updateAll',
      field: name,
      value: event.target.value,
    };

    this.props.updateResponseForm(payload);
  };
  handleDateChange = (name) => (dat) => {
    const date = dat.format('YYYY-MM-DD');
    const payload = {
      type: 'response',
      action: 'updateAll',
      field: name,
      value: date,
    };

    this.props.updateResponseForm(payload);
  };
  handleChecked = () => {
    this.setState({ statusChecked: !this.state.statusChecked });
  };

  buildOrders = () => {
    const payload = {
      type: 'response',
      action: 'buildOrders',
    };
    this.props.updateResponseForm(payload);
  };

  updatePrices = () => {
    const payload = {
      type: 'response',
      action: 'updatePrices',
    };
    this.props.updateResponseForm(payload);
  };

  updateOrderRow = (actionType) => (payload) => {
    if (actionType === 'defaultOrder') {
      payload.action = 'updateDefaultOrderRow';
    }
    this.props.updateResponseForm(payload);
  };

  getErrors = () => {
    const orders = this.props.ordersRFQ || {};
    let error = false;
    orders.forEach((order) => {
      if (!checkPrice(order.price)) {
        error = true;
      }
      if (!checkVolume(order.volume)) {
        error = true;
      }
    });

    return error;
  };
  sendOrder = () => {
    const hasRowErrors = this.getErrors();
    const hasRecipientErrors = this.getErrorSelectedOrganisationsIds();
    let orderRows = this.props.ordersRFQ;
    //const { orderIdToCancel } = this.props;
    const defaultOrders = this.props.defaultOrders;
    if (!hasRowErrors && !hasRecipientErrors.value) {
      orderRows.forEach((row) => {
        row.textMessage = this.state.textMessage;
      });
      const textMessage = this.state.textMessage || '';
      const createOrderPayload = {
        orderRows,
        textMessage,
        defaultOrders,
        timeInForce: this.state.timeInForce,
        //orderIdToCancel: orderIdToCancel,
        status: this.state.statusChecked ? 'SUSPENDED' : 'ACTIVE',
        callback: this.handleSend,
      };

      /*  if (orderIdToCancel) {
        const orderId = this.props.orderIdToCancel;
        const orderToAmend = {
          orderId,
          status: status.CANCELLED,
          //assets: order.assets,
        };
        const payload = { items: [orderToAmend] };
        this.props.updateOrders(payload);
      } */
      this.props.createOrder(createOrderPayload);
      this.setState({ textMessage: '' });
      //this.closeForm();
    }
  };

  handleSend = (success) => {
    if (success) {
      this.setState({ snackbarSuccessOpen: true });
      const { orderToCancel } = this.props;
      if (orderToCancel) {
        const orderId = this.props.orderToCancel.id;
        const orderToAmend = {
          orderId,
          status: status.CANCELLED,
          //assets: order.assets,
        };
        const payload = { items: [orderToAmend] };
        this.props.updateOrders(payload);
      }
      this.closeForm();
    } else {
      this.setState({ snackbarErrorOpen: true });
    }
  };

  getOrgansisations = () => {
    const { activeRfq, organisationsObject, organisations, userOrganisation } = this.props;
    let orgs = [];

    if (activeRfq) {
      if (activeRfq.visibility === 'SELECTED_ORGANISATIONS') {
        activeRfq.selectedOrganisationsIds.forEach((id) => {
          orgs.push(organisationsObject[id]);
        });
      } else if (activeRfq.visibility === 'TRADING_PARTNERS') {
        const tradingPartners = userOrganisation.tradingPartnersIds || [];
        tradingPartners.forEach((id) => {
          orgs.push(organisationsObject[id]);
        });
      } else {
        orgs = organisations;
      }
    }

    return orgs;
  };

  closeForm = () => {
    const payload = {
      type: 'response',
      action: 'closeForm',
    };

    this.props.updateResponseForm(payload);
  };

  getVisibilityOptions = (activeRfq) => {
    if (activeRfq === undefined || activeRfq === null) {
      return visibilities;
    }
    if (activeRfq.visibility === visibilities.PUBLIC) {
      return visibilities;
    }
    if (activeRfq.visibility === visibilities.TRADING_PARTNERS) {
      return {
        TRADING_PARTNERS: 'TRADING_PARTNERS',
        SELECTED_ORGANISATIONS: 'SELECTED_ORGANISATIONS',
      };
    }
    return { SELECTED_ORGANISATIONS: 'SELECTED_ORGANISATIONS' };
  };
  getErrorSelectedOrganisationsIds = () => {
    const { activeRfq, user, ordersRFQ } = this.props;
    if (activeRfq.borkerOrganisationId === user.organisationId) {
      if (ordersRFQ.length > 0) {
        if (ordersRFQ[0].visibility === visibilities.SELECTED_ORGANISATIONS) {
          if (ordersRFQ[0].selectedOrganisationsIds.length === 0) {
            return { message: ERROR_RECIPIENT, value: true };
          } else {
            return { message: '', value: false };
          }
        }
      }
    }
    return { message: '', value: false };
  };
  getFieldComponent = (field, order, orderRow, orderRowFields) => {
    const { enumerations } = this.props;

    switch (orderRowFields[field].component) {
      case 'text':
        return (
          <TextFieldFilled
            accessor={field}
            key={`${field}-${orderRow.key}`}
            value={order[field] || ''}
            handleChange={this.handleChange}
            width={FIELD_WIDTH}
          ></TextFieldFilled>
        );
      case 'select':
        return (
          <SelectField
            accessor={field}
            key={`${field}-${orderRow.key}`}
            value={order[field] || ''}
            values={enumerations[field]}
            handleChange={this.handleChange}
            width={FIELD_WIDTH}
          />
        );
      case 'date':
        return (
          <DateField
            accessor={field}
            value={orderRow[field]}
            disablePast={orderRowFields[field].disablePast}
            handleDateChange={this.handleDateChange}
            width={FIELD_WIDTH}
            key={`${field}-${orderRow.key}`}
            clearable={orderRowFields[field].clearable}
          />
        );
      default:
        return null;
    }
  };

  render() {
    const { classes, activeRfq, open, user, formFields, tradingPartners = [] } = this.props;

    const isRfqRequestor = activeRfq
      ? activeRfq.brokerOrganisationId === user.organisationId
      : false;
    const orders = this.props.ordersRFQ || [];
    console.log('orders', orders);
    const order = orders[0] || {};
    const enumerations = this.props.enumerations || [];

    const rfqDirection = activeRfq ? activeRfq.direction : 'SELL';
    const direction = order.direction;
    const directions = [direction];
    const beneficiaryOrganisationId = order.beneficiaryOrganisationId;
    const beneficiaryOrganisation = this.props.organisationsObject[beneficiaryOrganisationId] || {};
    //const visibility = order.visibility;
    //const visibilitiesOptions = this.getVisibilityOptions(activeRfq);
    const selectedOrganisations = order.selectedOrganisationsIds;
    const organisations = this.getOrgansisations();
    const errorSelectedOrganisationsIds = this.getErrorSelectedOrganisationsIds();

    const { timeInForce } = this.state;
    const orderRow = orders[0] || null;
    const orderRowFields = formFields.allFields || null;
    const orderRowFieldKeys = orderRowFields ? Object.keys(orderRowFields) : [];

    const formFieldsTop = orderRowFieldKeys.filter((f) => orderRowFields[f].formTopSection);
    const formFieldsLeft = orderRowFieldKeys.filter((f) => orderRowFields[f].formLeftSection);
    const formFieldsRight = orderRowFieldKeys.filter((f) => orderRowFields[f].formRightSection);
    const formFieldsBottom = orderRowFieldKeys.filter((f) => orderRowFields[f].formBottomSection);

    const beneficiariesIds = this.props.myOrganisation.beneficiariesIds || [];

    return (
      <React.Fragment>
        <Modal open={open} onClose={this.closeForm}>
          <div
            style={getModalStyle()}
            className={
              (isRfqRequestor || direction === 'BUY') && this.state.showDetails
                ? classes.paper
                : classes.paperNotRfqOwner
            }
          >
            <Typography className={classes.title}>
              <CustomText value="rfqResponse" upperCase />
            </Typography>
            <div className={classes.container}>
              <div className={classes.section}>
                <div className={classes.subsectionWrap}>
                  <DirectionField
                    accessor="direction"
                    displayName="Direction"
                    value={direction}
                    values={['BUY', 'SELL']}
                    //values={directions}
                    handleChange={this.handleChange}
                  />

                  {order.visibility === 'SELECTED_ORGANISATIONS' && isRfqRequestor && (
                    <MultipleSelectFieldOrgs
                      key="selectedOrganisationsIds"
                      accessor="selectedOrganisationsIds"
                      displayName={<CustomText value="client" />}
                      value={selectedOrganisations}
                      values={organisations}
                      handleChange={this.handleChange}
                      errorRecipientsMessage={errorSelectedOrganisationsIds.message}
                      errorValue={errorSelectedOrganisationsIds.value}
                      width="360px"
                    />
                  )}
                  {beneficiariesIds.length > 0 && (
                    <SelectFieldOrgs
                      accessor="beneficiaryOrganisationId"
                      displayName="beneficiary"
                      value={order.beneficiaryOrganisationId || {}}
                      values={[...beneficiariesIds, this.props.myOrganisation.id]}
                      handleChange={this.handleChange}
                      orgs
                      width="360px"
                    />
                  )}
                  <SelectField
                    accessor="validity"
                    value={timeInForce !== null ? 'TIME IN FORCE' : 'GTC'}
                    values={['GTC', 'TIME IN FORCE']}
                    handleChange={this.handleTimeInForce}
                  />
                  {timeInForce !== null && (
                    <DateTimeField
                      accessor="timeInForce"
                      value={timeInForce}
                      handleChange={this.handleTimeInForce}
                    />
                  )}
                </div>
              </div>

              <div className={classes.sectionFlex}>
                {(isRfqRequestor || rfqDirection === 'BUY') && this.state.showDetails && (
                  <React.Fragment>
                    <div style={{ flex: 1 }}>
                      <Typography className={classes.banner}>
                        <CustomText value="company" upperCase />
                      </Typography>

                      <Card style={{ margin: '0px 4px 4px', padding: '8px' }}>
                        {orderRowFields &&
                          formFieldsTop.map((field) => {
                            if (orderRowFields[field] && orderRow) {
                              return this.getFieldComponent(field, order, orderRow, orderRowFields);
                            } else return null;
                          })}
                      </Card>
                    </div>
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
                      <div style={{ flex: 1 }}>
                        <Typography className={classes.banner}>
                          <CustomText value="product" upperCase />
                        </Typography>

                        <Card style={{ margin: '0px 4px 4px', padding: '8px' }}>
                          {orderRowFields &&
                            formFieldsLeft.map((field) => {
                              if (orderRowFields[field] && orderRow) {
                                return this.getFieldComponent(
                                  field,
                                  order,
                                  orderRow,
                                  orderRowFields,
                                );
                              } else return null;
                            })}
                        </Card>
                      </div>
                      <div style={{ flex: 1 }}>
                        <Typography className={classes.banner}>
                          <CustomText value="freight" upperCase />
                        </Typography>

                        <Card style={{ margin: '0px 4px 4px', padding: '8px' }}>
                          {orderRowFields &&
                            formFieldsRight.map((field) => {
                              if (orderRowFields[field] && orderRow) {
                                return this.getFieldComponent(
                                  field,
                                  order,
                                  orderRow,
                                  orderRowFields,
                                );
                              } else return null;
                            })}
                        </Card>
                      </div>
                    </div>
                    <div style={{ flex: 1 }}>
                      <Typography className={classes.banner}>
                        <CustomText value="offer" upperCase />
                      </Typography>

                      <Card style={{ margin: '0px 4px 4px', padding: '8px' }}>
                        {orderRowFields &&
                          formFieldsBottom.map((field) => {
                            if (orderRowFields[field] && orderRow) {
                              return this.getFieldComponent(field, order, orderRow, orderRowFields);
                            } else return null;
                          })}
                      </Card>
                    </div>
                  </React.Fragment>
                )}
                <div style={{ flex: 1 }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    {' '}
                    <SwitchField
                      offLabel={<CustomText value="live" upperCase />}
                      onLabel={<CustomText value="indic" upperCase />}
                      handleChecked={this.handleChecked}
                      checked={this.state.statusChecked}
                    />
                    {(isRfqRequestor || rfqDirection === 'BUY') && (
                      <ClearButton
                        icon="visibility"
                        title={'details'}
                        onClick={() => this.setState({ showDetails: !this.state.showDetails })}
                      />
                    )}
                    <ClearButton icon="add" title={'addItem'} onClick={() => this.addOrderRow()} />
                  </div>

                  <div className={classes.orders}>
                    {orders.map((row, index) => {
                      return (
                        <ResponseFormRow
                          key={row.key}
                          orderRow={row}
                          activeRfq={activeRfq}
                          copyOrderRow={() => this.copyOrderRow(row.key)}
                          deleteOrderRow={() => this.deleteOrderRow(row.key)}
                          updateOrderRow={this.updateOrderRow('order')}
                          index={index}
                          enumerations={enumerations}
                          formFields={formFields.defaultRowFields}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.totals}>
              <TotalsRow rows={orders} direction={directions[0]} />
            </div>
            <ToolbarBottom>
              <>
                <CancelButton title={'cancel'} onClick={this.closeForm} />
                <ConfirmButton title={'send'} onClick={this.sendOrder} />
              </>
            </ToolbarBottom>
          </div>
        </Modal>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={this.state.snackbarSuccessOpen}
          autoHideDuration={6000}
          onClose={() => this.setState({ snackbarSuccessOpen: false })}
        >
          <Alert
            onClose={() => this.setState({ snackbarSuccessOpen: false })}
            severity="info"
            sx={{ width: '100%' }}
          >
            <CustomText value="orderRequestSuccess" upperCase />
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={this.state.snackbarErrorOpen}
          autoHideDuration={6000}
          onClose={() => this.setState({ snackbarErrorOpen: false })}
        >
          <Alert
            onClose={() => this.setState({ snackbarErrorOpen: false })}
            severity="error"
            sx={{ width: '100%' }}
          >
            <CustomText value="orderRequestFail" upperCase />
          </Alert>
        </Snackbar>
      </React.Fragment>
    );
  }
}

ResponseForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    orders: getOrders(state),
    user: getActiveUser(state),
    userOrganisation: getActiveUserOrganisation(state),
    organisations: getOrganisations(state),
    organisationsObject: getOrganisationsObject(state),
    open: isOpenFormResponse(state),
    activeRfq: getRfqResponseForm(state),
    ordersRFQ: getOrdersResponseForm(state),
    enumerations: getOrdersResponseFormEnumerations(state),
    formFields: getResponseFormFields(state),
    defaultOrders: getDefaultOrdersResponseForm(state),
    orderToCancel: getOrderToCancelResponseForm(state),
    myOrganisation: getMyOrganisation(state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateResponseForm: (payload) => {
      dispatch(updateForm(UPDATE_RESPONSE_FORM, payload));
    },
    createOrder: (payload) => {
      dispatch(orders.actions.createOrder(orders.constants.CREATE_ORDER_FROM_RFQ, payload));
    },
    updateOrders: (payload) => {
      dispatch(orders.actions.updateOrders(orders.constants.UPDATE_ORDERS, payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ResponseForm));
