import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import AppMenu from '../../commons/components/AppMenu';

import GridLayout from './GridLayout';
import { createAction } from '../../commons/actions';
import { INIT_PROCESS } from '../../commons/constants';

import users from '../../modules/users';
import organisations from '../../modules/organisations';
import orders from '../../modules/orders';
import contracts from '../../modules/contracts';
import market from '../../modules/market';
import workspaces from '../../modules/workspaces';
import calculators from '../../modules/calculators';
import rfqs from '../../modules/rfqs';
import fxrates from '../../modules/fxrates';
import trades from '../../modules/trades';
import admin from '../../modules/admin';
import auth from '../../modules/auth';
import schemas from '../../modules/schemas';
import logos from '../../modules/logos';
import { useLanguage } from '../../commons/contexts/LanguageProvider';
const styles = (theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default,
    height: '100%',
    width: '100%',
    marginLeft: '55px',
    marginTop: '83px',
  },
  '@global': {
    '*::-webkit-scrollbar': {
      width: '12px',
      height: '12px',
      backgroundColor: 'transparent',
    },

    '*::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.scrollbar,
      borderRadius: '20px',
    },
    '*::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#30a9a4',
      borderRadius: '20px',
    },
    '*::-webkit-scrollbar-corner': {
      backgroundColor: theme.palette.background.default,
    },
  },
});

const ClientPage = (props) => {
  const [language, setLanguage] = useState(null);
  useEffect(() => {
    const {
      init,
      initWorkspace,
      initOrdersSettings,
      initCalculatorsSettings,
      getContract,
      getMarket,
      getUsers,
      getOrganisations,
      getMyOrganisation,
      getOrders,
      getRFQs,
      getFXRates,
      getTrades,
      getBrokers,
      initSchemas,
      initLanguage,
      getLogos,
    } = props;

    setTimeout(() => {
      init();
    }, 0);

    setTimeout(() => {
      getContract();
    }, 0);
    setTimeout(() => {
      initSchemas();
    }, 0);
    setTimeout(() => {
      initLanguage();
    }, 0);
    setTimeout(() => {
      getMarket();
    }, 0);

    setTimeout(() => {
      initOrdersSettings();
    }, 0);

    setTimeout(() => {
      initCalculatorsSettings();
    }, 0);
    setTimeout(() => {
      initWorkspace();
    }, 0);
    setTimeout(() => {
      getFXRates();
    }, 0);

    setTimeout(() => {
      getUsers();
    }, 0);

    setTimeout(() => {
      getOrganisations();
    }, 0);
    setTimeout(() => {
      getMyOrganisation();
    }, 0);
    setTimeout(() => {
      getBrokers();
    }, 0);

    setTimeout(() => {
      getOrders();
    }, 0);

    setTimeout(() => {
      getRFQs();
    }, 0);
    setTimeout(() => {
      getTrades();
    }, 0);
    setTimeout(() => {
      getLogos();
    }, 0);
  }, []);

  useEffect(() => {
    if (props.languageString) {
      setLanguage(props.languageString);
    }
  }, [props.languageString]);

  if (language) {
    const { changeLanguage } = useLanguage();
    changeLanguage(language);
    setLanguage(null);
  }

  return (
    <div className={props.classes.main}>
      <AppMenu />
      <GridLayout />
      <orders.components.ManageOrderLists />
      <rfqs.components.forms.Form />
      <rfqs.components.forms.ResponseForm />
      <rfqs.components.forms.AmendForm />
      <rfqs.components.forms.ExecuteTradeForm />
      <workspaces.components.ManageWorkspaces />
      <trades.components.TradeDetails />
      {/* <trades.components.PostTradeForm /> */}
      {/*  <trades.components.TradeAmendment /> */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isSuperAdmin: auth.selectors.isSuperAdmin(state),
    languageString: schemas.selectors.getLanguageString(state),
  };
};
function mapDispatchToProps(dispatch) {
  return {
    init: () => dispatch(createAction(INIT_PROCESS)),
    initSchemas: (payload) => dispatch(createAction(schemas.actionTypes.INIT_SCHEMAS, payload)),
    initLanguage: () => dispatch(createAction(schemas.actionTypes.INIT_LANGUAGE)),
    getUsers: () => dispatch(createAction(users.constants.GET_USERS)),
    getOrganisations: () => dispatch(createAction(organisations.constants.GET_ORGANISATIONS)),
    getMyOrganisation: () => dispatch(createAction(organisations.constants.GET_MY_ORGANISATION)),
    getOrders: () => dispatch(createAction(orders.constants.GET_ORDERS)),
    getRFQs: () => dispatch(createAction(rfqs.constants.GET_RFQS)),
    getContract: () => dispatch(createAction(contracts.constants.GET_CONTRACT)),
    getMarket: () => dispatch(createAction(market.constants.GET_MARKET)),
    getFXRates: () => dispatch(createAction(fxrates.constants.GET_FXRATES)),
    getTrades: () => dispatch(createAction(trades.constants.GET_TRADES)),
    getBrokers: () => dispatch(createAction(admin.constants.GET_BROKERS)),
    initOrdersSettings: () => dispatch(createAction(orders.constants.INIT_ORDERS_SETTINGS)),
    initCalculatorsSettings: () =>
      dispatch(createAction(calculators.constants.INIT_CALCULATORS_SETTINGS)),
    initWorkspace: () => dispatch(createAction(workspaces.constants.INIT_WORKSPACE)),
    getLogos: () => dispatch(createAction(logos.actionTypes.GET_LOGOS)),
  };
}

ClientPage.propTypes = {
  classes: PropTypes.object.isRequired,
  init: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ClientPage));
