import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import UserProfile from './UserProfile';
import { Icon, TextField, Paper } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MultipleSelectFieldFilled from '../../../commons/components/formFields/multipleSelectFieldFilled';
import CustomText from '../../../commons/components/formFields/customText';

const styles = (theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  listItem: {
    flex: 1,
  },
  filterRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: theme.spacing(),
  },
  icon: {
    padding: theme.spacing(),
  },
});

const Users = (props) => {
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState(null);
  const [userValue, setUserValue] = useState(null);
  const [userInputValue, setUserInputValue] = useState(null);
  const [species, setSpecies] = useState([]);
  const [connectionState, setConnectionState] = useState(null);
  const {
    organisationsObject,
    isSuperAdmin,
    isOrganisationAdmin,
    myOrganisation,
    classes,
    organisations,
  } = props;

  const getUsers = () => {
    const organisation = value;
    const user = userValue;
    const users = [...props.users]

      .filter((u) => (organisation !== null ? u.organisationId === organisation.id : true))
      .filter((u) => (user !== null ? u.id === user.id : true))
      .filter((u) => {
        if (species.length > 0) {
          const array1 = [...species];
          const array2 = u.details ? (u.details.species ? u.details.species : []) : [];
          const filteredArray = array1.filter((value) => array2.includes(value));
          return filteredArray.length > 0;
        } else {
          return true;
        }
      })
      .filter((u) => (connectionState !== null ? u.connectionState === 'online' : true));
    return users;
  };

  const users = getUsers();
  const CustomPaper = (props) => {
    return <Paper elevation={8} {...props} />;
  };
  const _loggedIn = CustomText({ value: 'loggedIn' });
  return (
    <React.Fragment>
      <div className={classes.filterRow}>
        <div className={classes.icon}>
          <Icon>filter_list</Icon>
        </div>
        <Autocomplete
          id="combo-box-demo"
          options={props.users}
          getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
          style={{ width: 256, margin: 2 }}
          PaperComponent={CustomPaper}
          renderInput={(params) => (
            <TextField
              {...params}
              label={<CustomText value="searchUser" />}
              variant="filled"
              size="small"
            />
          )}
          onChange={(e, value) => setUserValue(value)}
          onInputChange={(e, value) => setUserInputValue(value)}
        />
        <Autocomplete
          id="organisations"
          options={organisations}
          getOptionLabel={(option) => option.name}
          style={{ width: 256, margin: 2 }}
          PaperComponent={CustomPaper}
          renderInput={(params) => (
            <TextField
              {...params}
              label={<CustomText value="searchOrganisation" />}
              variant="filled"
              size="small"
            />
          )}
          onChange={(e, value) => setValue(value)}
          onInputChange={(e, value) => setInputValue(value)}
        />
        <MultipleSelectFieldFilled
          accessor="species"
          displayName={'species'}
          addUnderline
          value={species}
          values={props.species || []}
          handleChange={(name) => (e) => {
            setSpecies(e.target.value);
          }}
          width={200}
        />
        <Autocomplete
          id="connectionState"
          options={[_loggedIn]}
          getOptionLabel={(option) => option}
          style={{ width: 200, margin: 2 }}
          PaperComponent={CustomPaper}
          renderInput={(params) => (
            <TextField {...params} label={_loggedIn} variant="filled" size="small" />
          )}
          onChange={(e, value) => setConnectionState(value)}
          //onInputChange={(e, value) => this.setState({ inputValue: value })}
        />
      </div>

      {users.map((user) => {
        return (
          <UserProfile
            user={user}
            organisationsObject={organisationsObject}
            key={user.id}
            editUser={props.editUser}
            isSuperAdmin={isSuperAdmin}
            isOrganisationAdmin={isOrganisationAdmin}
            myOrganisation={myOrganisation}
            myUser={props.myUser}
          />
        );
      })}
    </React.Fragment>
  );
};

Users.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Users);
