import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import { withStyles } from '@material-ui/core/styles';
import MultipleSelectFieldFilled from '../../../commons/components/formFields/multipleSelectFieldFilled';
import SelectField from '../../../commons/components/formFields/SelectField';
import CheckboxField from '../../../commons/components/formFields/checkboxField';
import CollapseContainer from '../../../commons/components/collapseContainer';
import DateField from '../../../commons/components/formFields/dateField';
import { CancelButton, ConfirmButton } from '../../../commons/components/buttons';
import Tab from './Tab';
import EndAllRfqs from './EndAllRfqs';
import CustomText from '../../../commons/components/formFields/customText';

import {
  getAll,
  getViews,
  getDismissed,
  getUserId,
  getSendersRFQs,
  getRecipientsRFQs,
  getOrganisationsObject,
  getMyActiveRfqs,
} from '../selectors';
import { UPDATE_FORM, UPDATE_RFQ_VIEWS, UPDATE_RFQ_DISMISSED } from '../constants';
import { updateForm as actionUpdateForm, updateRFQViews as actionUpdateRFQViews } from '../actions';
import contract from '../../contracts/models/orderUnderlying';
import authModule from '../../auth';
const styles = (theme) => ({
  rfqTabBorder: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    border: '1px solid',
    borderColor: theme.palette.widgetBorder,
    backgroundColor: theme.palette.background.primary,
    maxWidth: '320px',
    minWidth: '320px',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '72px',
    padding: '16px 16px',
  },

  headerTitle: {
    color: theme.palette.text.primaryLight,
    fontSize: theme.typography.fontSize * 1.5,
    letterSpacing: '1px',
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.palette.background.dark,
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '72px',
    padding: '0px 8px',
  },
  buttonContainer: {
    width: '132px',
    display: 'flex',
    justifyContent: 'right',
  },
  listTabContainer: {
    overflowY: 'scroll',
    flex: 1,
    padding: theme.spacing(),
  },
  grow: {
    flexGrow: 1,
  },
  tabContainer: {
    padding: '8px 0px',
  },
});

class List extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showActive: true,
      startDate: moment().add(-92, 'days'),
      endDate: null,
      sender: '',
      recipient: '',
      species: [],
      endAllRfqsOpen: false,
      filtersExpanded: false,
      direction: '',
    };
  }

  handleChecked = (name) => (event) => {
    this.setState({
      [name]: event.target.checked,
    });
  };

  filterRFQs = (rfqs) => {
    const { showActive, sender, recipient, startDate, endDate, species, direction } = this.state;
    const { organisationsObject, rfqDismissed } = this.props;
    const filteredRFQs = rfqs
      .filter((item) => {
        if (showActive === true) {
          return item.status === 'ACTIVE';
        }
        return true;
      })
      .filter((item) => {
        if (showActive === true) {
          const index = rfqDismissed.findIndex((n) => n === item.id);
          return index === -1;
        }
        return true;
      })
      .filter((item) => {
        if (!direction) {
          return true;
        }

        return item.direction === direction;
      })
      .filter((item) => {
        if (!species) {
          return true;
        }
        if (Array.isArray(species)) {
          if (species.length === 0) {
            return true;
          } else {
            return species.some((value) => value === item.contract.underlying.species);
          }
        } else {
          return item.contract.underlying.species === species;
        }
      })

      .filter((item) => {
        if (!sender) {
          return true;
        }

        return (
          organisationsObject[item.organisationId] &&
          organisationsObject[item.organisationId].shortName === sender
        );
      })
      .filter((item) => {
        if (!recipient) {
          return true;
        }
        const orgs = item.selectedOrganisationsIds || [];

        return orgs.some(
          (o) => organisationsObject[o] && organisationsObject[o].shortName === recipient,
        );
      })
      .filter((item) => {
        if (!startDate) {
          return true;
        }
        return moment(item.createdTime) > moment(startDate).startOf('day');
      })
      .filter((item) => {
        if (!endDate) {
          return true;
        }
        return moment(item.createdTime) < moment(endDate).endOf('day');
      });

    filteredRFQs.sort((a, b) => {
      a = moment(a.createdTime);
      b = moment(b.createdTime);
      return a > b ? -1 : a < b ? 1 : 0;
    });

    return filteredRFQs;
  };

  requestForQuote = () => {
    const payload = {
      type: 'create',
      action: 'open',
    };

    this.props.updateForm(payload);
  };
  updateRfqDismissed = (payload) => {
    this.props.updateRfqViews(payload);
    this.props.updateRfqDismissed(payload);
  };

  viewRfq = (payload) => {
    this.props.tabClick(payload);
    this.props.updateRfqViews({ id: payload });
  };

  render() {
    const {
      sender,
      species,
      recipient,
      startDate,
      endDate,
      showActive,
      endAllRfqsOpen,
      direction,
    } = this.state;

    const { classes, senders, recipients, rfqs, myActiveRfqs, contacts, userId, isSuperAdmin } =
      this.props;
    const allSpecies = Object.keys(contract.fields.species.enum);
    const filteredRfqs = this.filterRFQs(rfqs); // TODO: review to extract into store. NEED REFACTOR. NOT FILTER INTO RENDER

    return (
      <div className={classes.rfqTabBorder}>
        <div className={classes.header}>
          <div className={classes.headerTitle}>
            <CustomText value="allOffers" upperCase />
          </div>
          <div className={classes.buttonContainer}>
            {!isSuperAdmin && (
              <ConfirmButton
                title={'createNew'}
                icon="addCircleOutline"
                onClick={this.requestForQuote}
                style={{ width: '100%' }}
              ></ConfirmButton>
            )}
          </div>
        </div>

        <div className={classes.listTabContainer}>
          {filteredRfqs.map((rfq) => {
            const selected = this.props.activeRfqId === rfq.id;

            return (
              <div className={classes.tabContainer} key={rfq.id}>
                <Tab
                  key={rfq.id}
                  data={rfq}
                  contacts={contacts}
                  tabClick={() => this.viewRfq(rfq.id)}
                  dismissable
                  viewable
                  updateRfqViews={this.updateRfqDismissed}
                  selected={selected}
                />
              </div>
            );
          })}
        </div>
        <CollapseContainer
          onClick={() =>
            this.setState({
              filtersExpanded: !this.state.filtersExpanded,
            })
          }
          expanded={this.state.filtersExpanded}
          title="filters"
          noFlex
        >
          <div
            style={{
              backgroundColor: 'transparent',
            }}
          >
            <CheckboxField
              accessor="showActive"
              displayName={'listOnlyActiveRequests'}
              value={showActive}
              handleChecked={this.handleChecked}
              fullWidth
            />
            <MultipleSelectFieldFilled
              accessor="species"
              displayName="species"
              disableUnderline={true}
              value={species}
              values={allSpecies}
              handleChange={(name) => (e) => {
                this.setState({ [name]: e.target.value });
              }}
              style={{ marginLeft: 0, marginRight: 0 }}
              fullWidth
            />

            <SelectField
              accessor="direction"
              displayName={'direction'}
              value={direction}
              values={['', 'BUY', 'SELL']}
              handleChange={(name) => (e) => this.setState({ [name]: e.target.value })}
              width={null}
              style={{ marginLeft: 0, marginRight: 0 }}
              fullWidth
            />
            <SelectField
              accessor="sender"
              displayName={'sender'}
              value={sender}
              values={senders}
              handleChange={(name) => (e) => this.setState({ [name]: e.target.value })}
              width={null}
              style={{ marginLeft: 0, marginRight: 0 }}
              fullWidth
            />
            <SelectField
              accessor="recipient"
              displayName={'recipient'}
              value={recipient}
              values={recipients}
              handleChange={(name) => (e) => this.setState({ [name]: e.target.value })}
              width={null}
              style={{ marginLeft: 0, marginRight: 0 }}
              fullWidth
            />
            <DateField
              accessor="startDate"
              displayName={'startDate'}
              value={startDate}
              disablePast={false}
              handleDateChange={(name) => (date) => {
                this.setState({
                  [name]: date,
                });
              }}
              style={{ marginLeft: 0, marginRight: 0 }}
              fullWidth
            />
            <DateField
              accessor="endDate"
              displayName={'endDate'}
              value={endDate}
              disablePast={false}
              handleDateChange={(name) => (date) => {
                this.setState({
                  [name]: date,
                });
              }}
              style={{ marginLeft: 0, marginRight: 0 }}
              fullWidth
              clearable
            />
          </div>
        </CollapseContainer>

        <div className={classes.footer}>
          <div className={classes.headerTitle}></div>
          <div className={classes.buttonContainer}>
            {!isSuperAdmin && (
              <CancelButton
                title={'endRequests'}
                onClick={() => this.setState({ endAllRfqsOpen: true })}
              ></CancelButton>
            )}
          </div>
        </div>

        <EndAllRfqs
          userId={userId}
          rfqs={myActiveRfqs}
          open={endAllRfqsOpen}
          close={() => this.setState({ endAllRfqsOpen: false })}
        />
      </div>
    );
  }
}

List.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    rfqs: getAll(state), // state.orders.rfqs,
    rfqViews: getViews(state),
    rfqDismissed: getDismissed(state),
    myActiveRfqs: getMyActiveRfqs(state),
    userId: getUserId(state), // state.logins.id,
    organisationsObject: getOrganisationsObject(state),
    senders: getSendersRFQs(state),
    recipients: getRecipientsRFQs(state),
    isSuperAdmin: authModule.selectors.isSuperAdmin(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateForm: (payload) => {
      dispatch(actionUpdateForm(UPDATE_FORM, payload));
    },
    updateRfqViews: (payload) => {
      dispatch(actionUpdateRFQViews(UPDATE_RFQ_VIEWS, payload));
    },
    updateRfqDismissed: (payload) => {
      dispatch(actionUpdateRFQViews(UPDATE_RFQ_DISMISSED, payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(List));
