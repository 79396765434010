import { takeEvery, put, select, call } from 'redux-saga/effects';

import constants from '../constants';

import {
  initFileUpload as actionInitFileUpload,
  destroyFileUpload as actionDestroyFileUpload,
  updateFileUpload as actionUpdateFileUpload,
  getFiles as actionGetFiles,
  createFile as actionCreateFile,
} from '../actions';

import { isObject } from '../../../commons/utils/functions';

import api from '../api';
import auth from '../../auth';
import workspaces from '../../workspaces';
import trades from '../../trades';

import { findSharedFiles, createFormData } from '../utils';

import { getAllObject } from '../selectors';

function* initFileUpload(action) {
  const {
    payload: { id, widget },
  } = action;

  const payload = {
    id,
    menu: [],
  };

  const meta = { receivedAt: new Date() };

  if (isObject(widget)) {
    payload.widget = widget;
  }
  yield put(actionInitFileUpload(constants.INIT_CHART_SUCCESS, payload, meta));
}

function* destroyFileUpload(action) {
  const payload = {
    id: action.payload.id,
  };

  const meta = { receivedAt: new Date() };

  yield put(actionDestroyFileUpload(constants.DESTROY_FILE_UPLOAD_SUCCESS, payload, meta));
}

function* updateFileUpload(action) {
  const {
    payload: { id, widget },
  } = action;

  const payload = {
    id,
  };

  const meta = { receivedAt: new Date() };

  if (isObject(widget)) {
    const newWidget = {
      ...widget,
      savedState: {},
    };

    yield put(
      workspaces.actions.updateWidget(workspaces.constants.UPDATE_WIDGET, { item: newWidget }),
    );

    payload.widget = newWidget;
  }

  yield put(actionUpdateFileUpload(constants.UPDATE_FILE_UPLOAD_SUCCESS, payload, meta));
}

function* createFile(action) {
  try {
    const token = yield call(auth.selectors.getToken);

    const formData = createFormData(action.payload.data);

    const data = {
      token,
      body: formData,
    };
    console.log('fileUpload:sagacreateFile:data', action.payload.data);

    const created = yield call(api.create, data);
    const payload = {
      fileList: [created],
    };
    const meta = { receivedAt: new Date() };
    action.payload.callback({ success: true, file: created });

    yield put(actionCreateFile(constants.CREATE_FILE_SUCCESS, payload, meta));
  } catch (error) {
    action.payload.callback({ success: false });
    console.log('File Upload Error:', error);
  }
}

function* deleteFile(action) {
  try {
    const token = yield call(auth.selectors.getToken);
    const { id } = action.payload;
    const data = {
      token,
    };

    const meta = { receivedAt: new Date() };
    const payload = {};
    const response = yield call(api.delete, id, data);
    payload.id = id;

    yield put(actionGetFiles(constants.DELETE_FILE_SUCCESS, payload, meta));
  } catch (error) {
    console.log('error:', error);
    const checkedError = yield call(auth.sagas.checkError, error); // TODO: sync operation. For asyn use fork

    if (!checkedError) {
      yield put(actionGetFiles(constants.DELETE_FILE_ERROR, { error }));
    }
  }
}

function* updateFileMeta(action) {
  try {
    const token = yield call(auth.selectors.getToken);
    const { id, metaData } = action.payload;
    const data = {
      token,
      body: metaData,
    };

    const meta = { receivedAt: new Date() };
    let payload = {};
    const response = yield call(api.updateMeta, id, data);

    payload = response;

    yield put(actionGetFiles(constants.UPDATE_FILE_META_SUCCESS, payload, meta));
  } catch (error) {
    console.log('error:', error);
    const checkedError = yield call(auth.sagas.checkError, error); // TODO: sync operation. For asyn use fork

    if (!checkedError) {
      yield put(actionGetFiles(constants.UPDATE_FILE_META_ERROR, { error }));
    }
  }
}

function* getFile(action) {}

function* getFileContent(action) {
  try {
    const token = yield call(auth.selectors.getToken);
    const { id } = action.payload;
    const data = {
      token,
    };

    const allFiles = yield select(getAllObject);
    let file = allFiles[id];
    if (!file) {
      file = yield call(api.get, id, data);
    }

    const meta = { receivedAt: new Date() };
    const payload = {};
    const fileContent = yield call(api.getContent, id, data);

    const preview = URL.createObjectURL(fileContent);

    file.preview = preview;
    file.blob = fileContent;

    payload.id = id;
    payload.file = file;
    if (action.payload.callback) {
      action.payload.callback(file);
    }

    yield put(actionGetFiles(constants.GET_FILE_CONTENT_SUCCESS, payload, meta));
  } catch (error) {
    console.log('error:', error);
    const checkedError = yield call(auth.sagas.checkError, error); // TODO: sync operation. For asyn use fork

    if (!checkedError) {
      yield put(actionGetFiles(constants.GET_FILE_CONTENT_ERROR, { error }));
    }
  }
}

function* getFiles(action) {
  try {
    const token = yield call(auth.selectors.getToken);

    const data = {
      token,
    };
    const meta = { receivedAt: new Date() };
    const payload = {};
    const fileList = yield call(api.getAll, data);
    const orgId = yield select(auth.selectors.getOrgId);
    const allTrades = yield select(trades.selectors.getAll);
    const sharedFileList = findSharedFiles(allTrades, orgId);

    payload.fileList = [...fileList, ...sharedFileList];
    yield put(actionGetFiles(constants.GET_FILES_SUCCESS, payload, meta));
  } catch (error) {
    console.log('error:', error);
    const checkedError = yield call(auth.sagas.checkError, error); // TODO: sync operation. For asyn use fork

    if (!checkedError) {
      yield put(actionGetFiles(constants.GET_FILES_ERROR, { error }));
    }
  }
}

export function* watchCreateFile() {
  yield takeEvery(constants.CREATE_FILE, createFile);
}

export function* watchGetFile() {
  yield takeEvery(constants.GET_FILE, getFile);
}
export function* watchDeleteFile() {
  yield takeEvery(constants.DELETE_FILE, deleteFile);
}
export function* watchUpdateFileMeta() {
  yield takeEvery(constants.UPDATE_FILE_META, updateFileMeta);
}

export function* watchGetFileContent() {
  yield takeEvery(constants.GET_FILE_CONTENT, getFileContent);
}

export function* watchGetFiles() {
  yield takeEvery(constants.GET_FILES, getFiles);
}

export function* watchInitFileUpload() {
  yield takeEvery(constants.INIT_FILE_UPLOAD, initFileUpload);
}

export function* watchDestroyFileUpload() {
  yield takeEvery(constants.DESTROY_FILE_UPLOAD, destroyFileUpload);
}

export function* watchUpdateFileUpload() {
  yield takeEvery(constants.UPDATE_FILE_UPLOAD, updateFileUpload);
}

function signOutSuccess() {}

export function* watchSignOutSuccess() {
  yield takeEvery(auth.constants.SIGN_OUT_SUCCESS, signOutSuccess);
}
