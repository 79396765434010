const defaultWorkspaces = [
  {
    name: 'Home',
    active: true,
    layouts: [
      {
        w: 30,
        h: 24,
        x: 18,
        y: 0,
        i: '1605111113428',
        moved: false,
        static: false,
      },
      {
        w: 18,
        h: 24,
        x: 0,
        y: 0,
        i: '1612382526668',
        moved: false,
        static: false,
      },
    ],
    widgets: [
      {
        type: 'RFQ',
        id: '1605111113428',
        title: 'RFQ',
        icon: {
          type: {
            type: {},
            compare: null,
            muiName: 'SvgIcon',
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        w: 32,
        h: 16,
        hasSearch: false,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        savedState: {},
        i: '1605111113428',
      },
      {
        type: 'CHAT',
        id: '1612382526668',
        title: 'Chat',
        icon: {
          type: {
            type: {},
            compare: null,
            muiName: 'SvgIcon',
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        w: 16,
        h: 16,
        hasSearch: false,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        savedState: {},
        i: '1612382526668',
      },
    ],
    id: '1599728844326',
  },
  {
    name: 'Real Time Trading Data',
    active: false,
    layouts: [
      {
        w: 24,
        h: 12,
        x: 24,
        y: 0,
        i: '1686787027167',
        moved: false,
        static: false,
      },
      {
        w: 24,
        h: 12,
        x: 0,
        y: 0,
        i: '1686787027932',
        moved: false,
        static: false,
      },
      {
        w: 24,
        h: 12,
        x: 24,
        y: 12,
        i: '1686787028714',
        moved: false,
        static: false,
      },
      {
        w: 24,
        h: 12,
        x: 0,
        y: 12,
        i: '1686787029106',
        moved: false,
        static: false,
      },
    ],
    widgets: [
      {
        type: 'STATISTICS',
        id: '1686787029106',
        title: 'Statistics',
        name: 'statistics',
        icon: {
          type: {
            type: {},
            compare: null,
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        w: 32,
        h: 16,
        hasSearch: false,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        savedState: {
          species: 'westernRock',
        },
        i: '1686787029106',
      },
      {
        type: 'STATISTICS',
        id: '1686787028714',
        title: 'Statistics',
        name: 'statistics',
        icon: {
          type: {
            type: {},
            compare: null,
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        w: 32,
        h: 16,
        hasSearch: false,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        savedState: {
          species: 'mexicoRed',
        },
        i: '1686787028714',
      },
      {
        type: 'STATISTICS',
        id: '1686787027932',
        title: 'Statistics',
        name: 'statistics',
        icon: {
          type: {
            type: {},
            compare: null,
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        w: 32,
        h: 16,
        hasSearch: false,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        savedState: {
          deliveryCountry: 'CHINA',
          dateFilter: 'tradeDate',
        },
        i: '1686787027932',
      },
      {
        type: 'STATISTICS',
        id: '1686787027167',
        title: 'Statistics',
        name: 'statistics',
        icon: {
          type: {
            type: {},
            compare: null,
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        w: 32,
        h: 16,
        hasSearch: false,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        savedState: {
          species: 'easternRock',
          deliveryCountry: 'CHINA',
        },
        i: '1686787027167',
      },
    ],
    id: '1607529793105',
  },
  {
    name: 'Future Trading Data',
    active: false,
    layouts: [
      {
        w: 24,
        h: 12,
        x: 24,
        y: 0,
        i: '1686787075697',
        moved: false,
        static: false,
      },
      {
        w: 24,
        h: 12,
        x: 0,
        y: 0,
        i: '1686787075858',
        moved: false,
        static: false,
      },
      {
        w: 24,
        h: 12,
        x: 24,
        y: 12,
        i: '1686787076344',
        moved: false,
        static: false,
      },
      {
        w: 24,
        h: 12,
        x: 0,
        y: 12,
        i: '1686787076697',
        moved: false,
        static: false,
      },
    ],
    widgets: [
      {
        type: 'STATISTICS',
        id: '1686787075858',
        title: 'Statistics',
        name: 'statistics',
        icon: {
          type: {
            type: {},
            compare: null,
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        w: 32,
        h: 16,
        hasSearch: false,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        savedState: {
          dateFilter: 'deliveryDate',
          deliveryCountry: 'CHINA',
        },
        i: '1686787075858',
      },
      {
        type: 'STATISTICS',
        id: '1686787075697',
        title: 'Statistics',
        name: 'statistics',
        icon: {
          type: {
            type: {},
            compare: null,
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        w: 32,
        h: 16,
        hasSearch: false,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        savedState: {
          species: 'easternRock',
          dateFilter: 'deliveryDate',
        },
        i: '1686787075697',
      },
      {
        type: 'STATISTICS',
        id: '1686787076344',
        title: 'Statistics',
        name: 'statistics',
        icon: {
          type: {
            type: {},
            compare: null,
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        w: 32,
        h: 16,
        hasSearch: false,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        savedState: {
          species: 'northAmericanLobster',
          dateFilter: 'deliveryDate',
        },
        i: '1686787076344',
      },
      {
        type: 'STATISTICS',
        id: '1686787076697',
        title: 'Statistics',
        name: 'statistics',
        icon: {
          type: {
            type: {},
            compare: null,
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        w: 32,
        h: 16,
        hasSearch: false,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        savedState: {
          species: 'westernRock',
          dateFilter: 'deliveryDate',
        },
        i: '1686787076697',
      },
    ],
    id: '1612380560223',
  },
  {
    name: 'Order Manager',
    active: false,
    layouts: [
      {
        w: 48,
        h: 24,
        x: 0,
        y: 0,
        i: '1686788401728',
        moved: false,
        static: false,
      },
    ],
    widgets: [
      {
        type: 'BLOTTERS',
        id: '1686788401728',
        title: 'Order and Trade Blotters',
        name: 'orderAndTradeBlotters',
        icon: {
          type: {
            type: {},
            compare: null,
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        w: 32,
        h: 16,
        hasSearch: true,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        savedState: {
          blotterSettings: {
            0: {
              columnState: [
                {
                  colId: 'updatedTime',
                  hide: false,
                  aggFunc: null,
                  width: 170,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.product',
                  hide: false,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.location',
                  hide: false,
                  aggFunc: null,
                  width: 95,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.weightClass',
                  hide: false,
                  aggFunc: null,
                  width: 65,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.incoTerms',
                  hide: false,
                  aggFunc: null,
                  width: 80,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'direction',
                  hide: false,
                  aggFunc: null,
                  width: 95,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'volume',
                  hide: false,
                  aggFunc: null,
                  width: 80,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'originalVolume',
                  hide: false,
                  aggFunc: null,
                  width: 95,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.unit',
                  hide: false,
                  aggFunc: null,
                  width: 95,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'price',
                  hide: false,
                  aggFunc: null,
                  width: 95,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.currency',
                  hide: false,
                  aggFunc: null,
                  width: 95,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'status',
                  hide: false,
                  aggFunc: null,
                  width: 95,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'rfqId',
                  hide: false,
                  aggFunc: null,
                  width: 95,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'createdTime',
                  hide: false,
                  aggFunc: null,
                  width: 95,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'user',
                  hide: false,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'organisation',
                  hide: false,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'textMessage',
                  hide: false,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.packingDate',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.deliveryDate',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.consignee',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.species',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.brandName',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.processingPoint',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.deliveryCountry',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.deliveryPoint',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.flightNumber',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.airwayBill',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.region',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.presentation',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.certification',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.colour',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.packaging',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.mortalityRateAllowance',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.weightAllowance',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.paymentTerms',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.cartonNetWeight',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'id',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'canTrade',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'organisationId',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'selectedOrganisationsIds',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'timeInForce',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'createdByUserId',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'updatedByUserId',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'visibility',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'rfqType',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'nominal',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'ownUser',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'ownName',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'ownShortName',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'ownComments',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'counterpartyUser',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'counterpartyName',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'counterpartyShortName',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'counterpartyComments',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'rfqComments',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'tradeId',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'cancellationStatus',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'cancellationRequestorShortName',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'cancellationInitiatedByOrgId',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'buyerData.purchaseOrderNumber',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'buyerData.purchaseOrderDate',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'buyerData.buyerNote',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'sellerData.invoiceNumber',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'sellerData.invoiceDate',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'sellerData.trackingNumber',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'sellerData.sellerNote',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
              ],
              sortState: [
                {
                  colId: 'updatedTime',
                  sort: 'desc',
                },
              ],
              filterState: {},
            },
            1: {
              columnState: [
                {
                  colId: 'sellerData.trackingNumber',
                  hide: false,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: 'left',
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'sellerData.invoiceNumber',
                  hide: false,
                  aggFunc: null,
                  width: 154,
                  pivotIndex: null,
                  pinned: 'left',
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.packingDate',
                  hide: false,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: 'left',
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.deliveryDate',
                  hide: false,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: 'left',
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.deliveryPoint',
                  hide: false,
                  aggFunc: null,
                  width: 180,
                  pivotIndex: null,
                  pinned: 'left',
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.consignee',
                  hide: false,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: 'left',
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.deliveryCountry',
                  hide: false,
                  aggFunc: null,
                  width: 98,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'counterpartyName',
                  hide: false,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'counterpartyShortName',
                  hide: false,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.processingPoint',
                  hide: false,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.brandName',
                  hide: false,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.species',
                  hide: false,
                  aggFunc: null,
                  width: 148,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.weightClass',
                  hide: false,
                  aggFunc: null,
                  width: 161,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'volume',
                  hide: false,
                  aggFunc: null,
                  width: 80,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.cartonNetWeight',
                  hide: false,
                  aggFunc: null,
                  width: 84,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'price',
                  hide: false,
                  aggFunc: null,
                  width: 95,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.unit',
                  hide: false,
                  aggFunc: null,
                  width: 95,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.currency',
                  hide: false,
                  aggFunc: null,
                  width: 95,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.paymentTerms',
                  hide: false,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'nominal',
                  hide: false,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.flightNumber',
                  hide: false,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.airwayBill',
                  hide: false,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.incoTerms',
                  hide: false,
                  aggFunc: null,
                  width: 80,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'direction',
                  hide: true,
                  aggFunc: null,
                  width: 95,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'originalVolume',
                  hide: true,
                  aggFunc: null,
                  width: 95,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'status',
                  hide: false,
                  aggFunc: null,
                  width: 95,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'cancellationStatus',
                  hide: false,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.location',
                  hide: true,
                  aggFunc: null,
                  width: 95,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'updatedTime',
                  hide: false,
                  aggFunc: null,
                  width: 170,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'createdTime',
                  hide: false,
                  aggFunc: null,
                  width: 162,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'ownUser',
                  hide: false,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'ownComments',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'counterpartyUser',
                  hide: false,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'sellerData.sellerNote',
                  hide: false,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'buyerData.buyerNote',
                  hide: false,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.product',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'counterpartyComments',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'rfqComments',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.region',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.presentation',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.certification',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.colour',
                  hide: false,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'rfqId',
                  hide: false,
                  aggFunc: null,
                  width: 292,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.packaging',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.mortalityRateAllowance',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'contract.underlying.weightAllowance',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'id',
                  hide: false,
                  aggFunc: null,
                  width: 328,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'textMessage',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'canTrade',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'organisationId',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'selectedOrganisationsIds',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'user',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'organisation',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'timeInForce',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'createdByUserId',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'updatedByUserId',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'visibility',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'rfqType',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'ownName',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'ownShortName',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'tradeId',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'cancellationRequestorShortName',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'cancellationInitiatedByOrgId',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'buyerData.purchaseOrderNumber',
                  hide: false,
                  aggFunc: null,
                  width: 210,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'buyerData.purchaseOrderDate',
                  hide: true,
                  aggFunc: null,
                  width: 192,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
                {
                  colId: 'sellerData.invoiceDate',
                  hide: true,
                  aggFunc: null,
                  width: 150,
                  pivotIndex: null,
                  pinned: null,
                  rowGroupIndex: null,
                  flex: 0,
                },
              ],
              sortState: [],
              filterState: {},
            },
          },
          tabValue: 1,
        },
        i: '1686788401728',
      },
    ],
    id: '1605180946728',
  },
  {
    name: 'Digital Wet Market',
    active: false,
    layouts: [
      {
        w: 24,
        h: 24,
        x: 0,
        y: 0,
        i: '1686787087836',
        moved: false,
        static: false,
      },
      {
        w: 24,
        h: 24,
        x: 24,
        y: 0,
        i: '1686787094268',
        moved: false,
        static: false,
      },
    ],
    widgets: [
      {
        type: 'ADMIN',
        id: '1686787087836',
        title: 'Organisations & Users',
        name: 'organisationsAndUsers',
        icon: {
          type: {
            type: {},
            compare: null,
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        w: 24,
        h: 12,
        hasSearch: false,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        savedState: {},
        i: '1686787087836',
      },
      {
        type: 'CHAT',
        id: '1686787094268',
        title: 'Chat',
        name: 'chat',
        icon: {
          type: {
            type: {},
            compare: null,
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        w: 16,
        h: 16,
        hasSearch: false,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        savedState: {},
        i: '1686787094268',
      },
    ],
    id: '1686717939198',
  },
  {
    name: 'Company Dashboard',
    active: false,
    layouts: [
      {
        w: 16,
        h: 12,
        x: 12,
        y: 0,
        i: '1687395852445',
        moved: false,
        static: false,
      },
      {
        w: 16,
        h: 12,
        x: 12,
        y: 12,
        i: '1687395853379',
        moved: false,
        static: false,
      },
      {
        w: 12,
        h: 24,
        x: 0,
        y: 0,
        i: '1687395865116',
        moved: false,
        static: false,
      },

      {
        w: 20,
        h: 24,
        x: 28,
        y: 0,
        i: '1687396041629',
        moved: false,
        static: false,
      },
    ],
    widgets: [
      {
        type: 'FXRATES',
        id: '1687395865116',
        title: 'FX Rates',
        name: 'fxRates',
        icon: {
          type: {
            type: {},
            compare: null,
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        w: 6,
        h: 12,
        hasSearch: false,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        savedState: {},
        i: '1687395865116',
      },

      {
        type: 'CHART',
        id: '1687395853379',
        title: 'Chart',
        name: 'chart',
        icon: {
          type: {
            type: {},
            compare: null,
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        w: 16,
        h: 16,
        hasSearch: false,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        savedState: {
          contractName: 'fishpool_forwards',
        },
        i: '1687395853379',
      },
      {
        type: 'CHART',
        id: '1687396041629',
        title: 'Chart',
        name: 'chart',
        icon: {
          type: {
            type: {},
            compare: null,
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        w: 16,
        h: 16,
        hasSearch: false,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        savedState: {
          contractName: 'lfex',
        },
        i: '1687396041629',
      },
      {
        type: 'CHART',
        id: '1687395852445',
        title: 'Chart',
        name: 'chart',
        icon: {
          type: {
            type: {},
            compare: null,
          },
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        w: 16,
        h: 16,
        hasSearch: false,
        component: {
          type: 'div',
          key: null,
          ref: null,
          props: {},
          _owner: null,
        },
        savedState: {
          contractName: 'lfex_4w',
        },
        i: '1687395852445',
      },
    ],
    id: '1686786985979',
  },
];

export default defaultWorkspaces;
