import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import OrganisationProfile from './OrganisationProfile';

import { Icon, TextField, Paper } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import MultipleSelectFieldFilled from '../../../commons/components/formFields/multipleSelectFieldFilled';
import CustomText from '../../../commons/components/formFields/customText';

const styles = (theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  listItem: {
    flex: 1,
  },
  filterRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: theme.spacing(),
  },
  icon: {
    padding: theme.spacing(),
  },
});

const Organisations = (props) => {
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [type, setType] = useState(null);
  const [species, setSpecies] = useState([]);
  const {
    organisations,
    organisationsObject,
    isSuperAdmin,
    isOrganisationAdmin,
    myOrganisation,
    users,
    classes,
  } = props;

  const getOrganisations = (organisations) => {
    if (value !== null) {
      return [value];
    } else {
      const newOrgs = [...organisations]
        .filter((o) => {
          if (type !== null) {
            return o.type === type;
          } else {
            return true;
          }
        })
        .filter((o) => {
          return o.name.toUpperCase().includes(inputValue.toUpperCase());
        })
        .filter((o) => {
          if (species.length > 0) {
            const array1 = [...species];
            const array2 = o.details ? (o.details.species ? o.details.species : []) : [];
            const filteredArray = array1.filter((value) => array2.includes(value));
            return filteredArray.length > 0;
          } else {
            return true;
          }
        });
      return newOrgs;
    }
  };

  const filteredOrgs = getOrganisations(organisations);

  const CustomPaper = (props) => {
    return <Paper elevation={8} {...props} />;
  };

  const types = (function () {
    const _buyer = CustomText({ value: 'buyer', upperCase: true });
    const _seller = CustomText({ value: 'seller', upperCase: true });
    const _agent = CustomText({ value: 'agent', upperCase: true });
    return [_buyer, _seller, _agent];
  })();

  return (
    <React.Fragment>
      <div className={classes.filterRow}>
        <div className={classes.icon}>
          <Icon>filter_list</Icon>
        </div>
        <Autocomplete
          id="combo-box-demo"
          options={organisations}
          getOptionLabel={(option) => option.name}
          style={{ width: 256, margin: '2px' }}
          PaperComponent={CustomPaper}
          renderInput={(params) => (
            <TextField
              {...params}
              label={<CustomText value="searchOrganisation" />}
              variant="filled"
              size="small"
            />
          )}
          onChange={(e, val) => {
            setValue(val);
          }}
          onInputChange={(e, val) => {
            setInputValue(val);
          }}
        />
        <Autocomplete
          id="combo-box-type"
          options={types}
          getOptionLabel={(option) => option}
          style={{ width: 200, margin: '2px' }}
          PaperComponent={CustomPaper}
          renderInput={(params) => (
            <TextField
              {...params}
              label={<CustomText value="type" />}
              variant="filled"
              size="small"
            />
          )}
          onChange={(e, value) => setType(value)}
        />

        <MultipleSelectFieldFilled
          accessor="species"
          displayName="Species"
          addUnderline
          value={species}
          values={props.species || []}
          handleChange={(name) => (e) => {
            setSpecies(e.target.value);
          }}
          width={200}
        />
      </div>
      {filteredOrgs.map((org, index) => {
        return (
          <OrganisationProfile
            organisation={org}
            organisationsObject={organisationsObject}
            editOrganisation={props.editOrganisation}
            key={org ? org.id : index}
            isSuperAdmin={isSuperAdmin}
            isOrganisationAdmin={isOrganisationAdmin}
            users={users}
            myOrganisation={myOrganisation}
          />
        );
      })}
    </React.Fragment>
  );
};

Organisations.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Organisations);
