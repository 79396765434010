import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import chatModule from '../../../chat';

import { getActiveUser, getOrganisationsObject, getUsersObject } from '../../selectors';

const styles = (theme) => {
  return {};
};

const Chat = (props) => {
  const { chatVisible, room } = props;

  return (
    <div>
      <chatModule.components.ActiveMessaging
        selectedChatId={room.id}
        isChatVisible={chatVisible}
        style={{ maxHeight: '360px' }}
      />
    </div>
  );
};

Chat.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => {
  return {
    user: getActiveUser(state),
    organisationsObject: getOrganisationsObject(state),
    chats: chatModule.selectors.getChats(state),
    usersObject: getUsersObject(state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    createRfqChat: (payload) => {
      dispatch(chatModule.actions.createRfqChat(payload));
    },
    updateChats: (payload) => {
      dispatch(chatModule.actions.updateChats(chatModule.constants.CHATS_UPDATE, payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Chat));
