import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { Menu, MenuItem, Divider, ListItemIcon, ListItemText, Icon } from '@material-ui/core';

import { getAllWorkspaces } from '../../selectors';

import CustomText from '../formFields/customText';

const styles = (theme) => ({});

const WorkspacesMenu = (props) => {
  return (
    <Menu
      anchorEl={props.anchorEl}
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={props.open}
      onClose={props.onClose}
    >
      {props.workspaces.map((w) => (
        <MenuItem key={w.name} onClick={() => props.onClick(w.id)}>
          {w.name}
        </MenuItem>
      ))}
      <Divider />
      <MenuItem
        onClick={() => {
          props.showManageWorkspaces({ action: 'showManage' });
          props.onClose();
        }}
      >
        <ListItemIcon>
          <Icon>edit</Icon>
        </ListItemIcon>
        <ListItemText primary={<CustomText value="workspaceEditor" />} />
      </MenuItem>
    </Menu>
  );
};

WorkspacesMenu.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    workspaces: getAllWorkspaces(state),
  };
};

export default connect(mapStateToProps)(withStyles(styles)(WorkspacesMenu));
